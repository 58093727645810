import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseFetchToken from "../hooks/UseFetchToken";
import { useLocation } from "react-router-dom";

import { Button } from "@radix-ui/themes";

const PasswordChange = () => {
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordChangeAllowedAmount, setPasswordChangeAllowedAmount] =
    useState(null);
  const [passwordValidationMessage, setPasswordValidationMessage] =
    useState("");
  const [
    confirmPasswordValidationMessage,
    setConfirmPasswordValidationMessage,
  ] = useState("");

  const passwordRegex =
    /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;

  const location = useLocation();

  useEffect(() => {
    // 컴포넌트가 마운트된 후에 해시 값에 따라 스크롤
    if (location.hash === "#password-change") {
      const element = document.getElementById("password-change");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const validateTeacherPassword = (password) => {
    if (!password) {
      return { isValid: false, message: "비밀번호를 입력해주세요." };
    }
    if (!passwordRegex.test(password)) {
      return {
        isValid: false,
        message:
          "비밀번호는 8자에서 20자 사이여야 하며, 문자, 숫자, 특수문자를 포함해야 합니다.",
      };
    }
    return { isValid: true, message: "사용 가능한 비밀번호입니다." };
  };

  const validatePasswordConfirm = (confirmPassword, originalPassword) => {
    if (!confirmPassword) {
      return { isValid: false, message: "비밀번호를 한 번 더 입력해주세요." };
    }
    if (confirmPassword !== originalPassword) {
      return { isValid: false, message: "비밀번호가 일치하지 않습니다." };
    }
    return { isValid: true, message: "비밀번호가 일치합니다." };
  };

  const handlePasswordChangeRequest = async (e) => {
    e.preventDefault();

    try {
      const data = await UseFetchToken(
        `/api/users/${localStorage.getItem("userId")}/password/change-auth`,
        {
          method: "PATCH",
          body: JSON.stringify({ loginPw: currentPassword }),
        }
      );

      if (data && data.passwordChangeAllowedAmount) {
        setPasswordChangeAllowedAmount(data.passwordChangeAllowedAmount);
        setIsChangingPassword(true);
        toast.info(
          `${data.passwordChangeAllowedAmount}분 이내에 비밀번호를 변경하세요.`,
          {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (error) {
      console.error("Error:", error);
      alert("비밀번호 인증 중 오류가 발생했습니다.");
    }
  };

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);

    const validation = validateTeacherPassword(value);
    setPasswordValidationMessage(validation.message);
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmNewPassword(value);

    const validation = validatePasswordConfirm(value, newPassword);
    setConfirmPasswordValidationMessage(validation.message);
  };

  const handleNewPasswordSubmit = async (e) => {
    e.preventDefault();
    setPasswordValidationMessage("");
    setConfirmPasswordValidationMessage("");

    const passwordValidation = validateTeacherPassword(newPassword);
    const confirmPasswordValidation = validatePasswordConfirm(
      confirmNewPassword,
      newPassword
    );

    if (!passwordValidation.isValid) {
      setPasswordValidationMessage(passwordValidation.message);
      return;
    }

    if (!confirmPasswordValidation.isValid) {
      setConfirmPasswordValidationMessage(confirmPasswordValidation.message);
      return;
    }

    try {
      const data = await UseFetchToken(
        `/api/users/${localStorage.getItem("userId")}/password/change-new`,
        {
          method: "PATCH",
          body: JSON.stringify({ loginPw: newPassword }),
        }
      );

      if (data && data.newPassword) {
        alert("비밀번호가 성공적으로 변경되었습니다.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("비밀번호 변경 중 오류가 발생했습니다. 다시 시도해주세요.");
    }
    setIsChangingPassword(false);
    // 현재 비밀번호, 새 비밀번호, 비밀번호 확인 모두 초기화
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  return (
    <div className="space-y-4 p-6">
      <ToastContainer />
      {!isChangingPassword ? (
        <>
          <form>
            <div>
              <label className="block mb-2 text-neutral-50 font-medium">
                현재 비밀번호
              </label>
              <input
                autoComplete="off"
                onPaste={(e) => e.preventDefault()} // Prevent paste
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="block w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="flex justify-center">
              <Button
                type="submit"
                color="blue"
                onClick={handlePasswordChangeRequest}
                className="w-full hover:bg-secondary transition-colors mt-4 py-2 px-4 rounded"
              >
                비밀번호 변경
              </Button>
            </div>
          </form>
        </>
      ) : (
        <form onSubmit={handleNewPasswordSubmit} className="space-y-4">
          <div>
            <label className="block mb-2 text-neutral-50 font-medium">
              새 비밀번호
            </label>
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              className="block w-full p-2 border border-gray-300 rounded"
              required
            />
            <p
              className={`mt-2 ${
                passwordValidationMessage.includes("사용 가능")
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {passwordValidationMessage}
            </p>
          </div>
          <div>
            <label className="block mb-2 text-neutral-50">새 비밀번호 확인</label>
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="password"
              value={confirmNewPassword}
              onChange={handleConfirmPasswordChange}
              className="block w-full p-2 border border-gray-300 rounded"
              required
            />
            <p
              className={`mt-2 ${
                confirmPasswordValidationMessage.includes("일치합니다")
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {confirmPasswordValidationMessage}
            </p>
          </div>
          <div className="flex justify-center">
            <p className="text-lg text-gray-600">
              {passwordChangeAllowedAmount}분 이내에 비밀번호를 변경하세요.
            </p>
          </div>
          <div className="flex justify-center">
            <Button
              type="submit"
              color="blue"
              className="w-full hover:bg-secondary transition-colors py-2 px-4 rounded mt-5"
            >
              비밀번호 저장
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default PasswordChange;

import React, { useState, useEffect, useRef } from "react";
import { StudyClassification } from "../constants/Constants";
import { toast } from "react-toastify";

const StudyWordWriting = ({
  word,
  ignoreCase,
  onSubmit,
  nextSlide,
  slideVisible,
  studyClassification,
}) => {
  const [input, setInput] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [lastSubmitted, setLastSubmitted] = useState("");
  const inputRef = useRef(null);
  const isPractice = studyClassification === StudyClassification.PRACTICE.name;

  useEffect(() => {
    if (slideVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [slideVisible]);

  const checkAnswer = (e) => {
    if (lastSubmitted === input) {
      toast.error("다른 단어를 입력해보세요", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const correct = ignoreCase
      ? word.answer?.toLowerCase() === input.toLowerCase()
      : word.answer === input;

    if (correct) {
      toast.success("훌륭합니다!", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      onSubmit(word.id, input, true);
      setTimeout(() => {
        nextSlide();
      }, 500);
    } else {
      setAttempts(attempts + 1);
      setLastSubmitted(input);

      toast.error("다시 한 번 고민해보세요", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getHint = () => {
    if (attempts >= 5) {
      return word.answer;
    } else if (attempts >= 3) {
      return word.answer.charAt(0) + " _".repeat(word.answer.length - 1);
    } else {
      return null;
    }
  };

  const handleChange = (e) => {
    setInput(e.target.value);
    onSubmit(word.id, e.target.value, e.key === "Enter");
  };

  function handleSubmit(e) {
    if (isPractice) {
      checkAnswer(e);
      return;
    }
    toast.success("제출되었습니다!", {
      position: "top-center",
      autoClose: 300,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
    onSubmit(word.id, input, true);
    setTimeout(() => {
      nextSlide();
    }, 300);
  }

  return (
    <div className="study-word-writing flex flex-col items-center">
      <div className="md:text-[3rem] max-md:text-3xl font-bold md:pb-6 max-w-[80%]">
        {word.question}
      </div>
      {isPractice && getHint() && (
        <div className="text-xl text-neutral-50">{getHint()}</div>
      )}
      <input
        ref={inputRef}
        type="text"
        className="mt-6 border border-primary rounded w-full max-w-3xl text-center text-4xl font-bold"
        value={input}
        onClick={() => inputRef.current.focus()}
        onChange={(e) => handleChange(e)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSubmit(e);
          }
        }}
      />
    </div>
  );
};

export default StudyWordWriting;

import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import UseFetchToken from "../hooks/UseFetchToken";
import { StudyStatus, StudyTarget, StudyType } from "../constants/Constants";
import { toast, ToastContainer } from "react-toastify";
import { Box, Badge } from "@radix-ui/themes";
import {} from "@radix-ui/react-icons";

const StudyResult = () => {
  const isTeacher = JSON.parse(localStorage.getItem("roles")).includes(
    "ROLE_TEACHER"
  );
  const [count, setCount] = useState(0);
  const [editingWordId, setEditingWordId] = useState(null); // 수정 중인 단어 ID 관리
  const [correct, setCorrect] = useState(null); // 정오 상태
  const [reason, setReason] = useState(null); // 수정 이유

  const { studyId } = useParams();
  const navigate = useNavigate();
  const [study, setStudy] = useState(null);
  const [studyWords, setStudyWords] = useState([]);
  const [student, setStudent] = useState(null);
  const [teacher, setTeacher] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (Boolean(studyId)) {
      const fetchStudy = async () => {
        const data = await UseFetchToken(`/api/studies/${studyId}`, {
          method: "GET",
        });

        if (data) {
          setStudy(data.study);
          setStudyWords(data.studyWords);
          setStudent(data.student);
          setTeacher(data.teacher);
        }
        setLoading(false);
      };
      fetchStudy();
    } else {
      setLoading(false);
      alert("학습 정보를 찾을 수 없습니다.");
      navigate(-1);
    }
  }, [studyId, count]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }

  const renderSchoolGradeLables = (schoolGrade) => {
    const grades = [
      { label: "1학년", value: 1 },
      { label: "2학년", value: 2 },
      { label: "3학년", value: 3 },
      { label: "4학년", value: 4 },
      { label: "5학년", value: 5 },
      { label: "6학년", value: 6 },
      { label: "1학년", value: 7 },
      { label: "2학년", value: 8 },
      { label: "3학년", value: 9 },
      { label: "1학년", value: 10 },
      { label: "2학년", value: 11 },
      { label: "3학년", value: 12 },
    ];
    return grades
      .filter((grade) => grade.value === schoolGrade)
      .map((grade) => grade.label);
  };

  const renderSubmitDateTime = (submitDateTime) => {
    if (!submitDateTime) {
      return "제출 전";
    }
    const date = new Date(submitDateTime);
    const year = date.getFullYear().toString().slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hour = ("0" + date.getHours()).slice(-2);
    const minute = ("0" + date.getMinutes()).slice(-2);
    const ampm = date.getHours() < 12 ? "오전" : "오후";
    return `${year}년 ${month}월 ${day}일 ${ampm} ${hour}시 ${minute}분`;
  };

  const handleEditClick = (wordId) => {
    if (editingWordId === wordId) {
      setEditingWordId(null);
      setCorrect(null);
      setReason(null);
    } else {
      setEditingWordId(wordId);
      setCorrect(null);
      setReason(null);
    }
  };

  const handleSaveClick = async (wordId) => {
    if (correct === null) {
      alert("정오 여부를 입력해주세요.");
      return;
    }

    try {
      const data = await UseFetchToken(
        `/api/studies/${studyId}/study-words/${wordId}/correct`,
        {
          method: "PATCH",
          body: JSON.stringify({
            correct: correct,
            reason: reason,
          }),
        }
      );

      if (data && data.studyWordId) {
        setCount(count + 1); // 페이지 새로고침 효과
        setEditingWordId(null); // 수정 모드 종료
        toast.success("수정되었습니다!", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else {
        alert("수정에 실패했습니다.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("수정 중 오류가 발생했습니다.");
    }
  };

  const handleDeleteClick = async () => {
    if (window.confirm("정말로 이 학습 기록을 삭제하시겠습니까?")) {
      try {
        const data = await UseFetchToken(`/api/studies/${studyId}`, {
          method: "DELETE",
        });

        if (data && data.studyId) {
          toast.success("삭제되었습니다.", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            navigate("/studies");
          }, 1000);
        } else {
          alert("삭제에 실패했습니다.");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("삭제 중 오류가 발생했습니다.");
      }
    }
  };

  return (
    <Box maxWidth="1245px" mx="auto" mb="9" className="max-md:p-5">
      <ToastContainer />
      {student &&
        (isTeacher ? (
          <div className="flex items-center mt-10 mb-16 gap-5 max-md:hidden">
            <h2 className="headline-m font-bold">💼{student.name}</h2>
            <Badge color="amber">
              {student.schoolName}{" "}
              {renderSchoolGradeLables(student.schoolGrade)} / {student.phone}
            </Badge>
          </div>
        ) : (
          <div className="flex items-center mt-10 mb-16 gap-5 max-md:hidden">
            <h2 className="headline-m font-bold">학습 상세조회</h2>
          </div>
        ))}

      {study && (
        <div className="mb-6 bg-neutral-5 p-4 rounded-lg">
          <div className="flex items-center gap-2">
            <div className="w-6 h-6 flex justify-center items-center bg-white rounded shadow">
              <svg
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 3.5C7.42857 1.85521 9.04723 1.01537 12.5714 1C12.6278 0.999796 12.6836 1.01043 12.7357 1.0313C12.7878 1.05217 12.8351 1.08285 12.8749 1.12158C12.9148 1.16031 12.9463 1.20633 12.9678 1.25697C12.9893 1.30762 13.0002 1.36189 13 1.41667V8.91667C13 9.02717 12.9548 9.13315 12.8745 9.2113C12.7941 9.28944 12.6851 9.33333 12.5714 9.33333C9.14286 9.33333 7.8183 10.0055 7 11M7 3.5C6.57143 1.85521 4.95277 1.01537 1.42857 1C1.37223 0.999796 1.31641 1.01043 1.26432 1.0313C1.21222 1.05217 1.16489 1.08285 1.12505 1.12158C1.08522 1.16031 1.05366 1.20633 1.03219 1.25697C1.01073 1.30762 0.999791 1.36189 1 1.41667V8.86641C1 9.1237 1.16393 9.33333 1.42857 9.33333C4.85714 9.33333 6.18652 10.0104 7 11M7 3.5V11"
                  stroke="#4E4E4E"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h2 className="text-lg font-semibold">{study.title}</h2>
          </div>
          <p className="mt-1">
            <b>학습 상태</b> {StudyStatus[study.status].value}
          </p>
          <p>
            <b>학습 유형</b> {StudyType[study.type].value}
          </p>
          <p>
            <b>학습 대상</b> {StudyTarget[study.target].value}
          </p>
          <p>
            <b>제출일</b> {renderSubmitDateTime(study.submitDateTime)}
          </p>
        </div>
      )}

      {teacher && (
        <div className="mb-5">
          <h2 className="">마지막으로 확인한 선생님: {teacher.name}</h2>
        </div>
      )}

      <div className="flex justify-between items-center mb-5 pt-4 border-t max-md:hidden">
        <h2 className="headline-m font-bold">학습 단어</h2>
        {isTeacher && (
          <div className="flex justify-center">
            <button
              className="px-3 py-2 text-warn bg-warn-light rounded"
              onClick={handleDeleteClick}
            >
              성적 삭제
            </button>
          </div>
        )}
      </div>

      <ul className="md:hidden">
        {studyWords.map((word) => (
          <React.Fragment key={word.id}>
            <li className="mb-2 rounded-lg p-4 border">
              <p className="text-neutral-70 font-semibold">질문</p>
              <p className="text-sm mb-2">{word.question}</p>

              <p className="text-neutral-70 font-semibold">제출된 답변</p>
              <p className="text-sm mb-2">{word.submit ? word.submit : "-"}</p>

              <p className="text-neutral-70 font-semibold">정답</p>
              <p className="text-sm mb-2">{word.answer}</p>

              <p className="text-neutral-70 font-semibold">정답 여부</p>
              <p className="text-sm mb-2">{word.correct ? "O" : "X"}</p>

              <p className="text-neutral-70 font-semibold">정오 채점 이유</p>
              <p className="text-sm">{word.reason || "-"}</p>
            </li>
          </React.Fragment>
        ))}
      </ul>

      <table className="min-w-full bg-white max-md:hidden">
        <thead>
          <tr className="bg-neutral-5 text-neutral-70">
            <th width="80"></th>
            <th className="py-2 px-4 text-left">질문</th>
            <th className="py-2 px-4 text-left">제출된 답변</th>
            <th className="py-2 px-4 text-left">정답</th>
            <th className="py-2 px-4 text-left">정답 여부</th>
            <th className="py-2 px-4 text-left">정오 채점 이유</th>
            {isTeacher && <th className="py-2 px-4 text-left"></th>}
          </tr>
        </thead>
        <tbody>
          {studyWords.map((word) => (
            <React.Fragment key={word.id}>
              <tr>
                <td></td>
                <td className="py-2 px-4">{word.question}</td>
                <td className="py-2 px-4">{word.submit}</td>
                <td className="py-2 px-4">{word.answer}</td>
                <td className="py-2 px-4">{word.correct ? "O" : "X"}</td>
                <td className="py-2 px-4">{word.reason || "-"}</td>
                {isTeacher && (
                  <td className="py-2 px-4">
                    <button
                      className={`px-4 py-1 ${
                        editingWordId === word.id
                          ? "text-red-500 border border-red-500"
                          : ""
                      }`}
                      onClick={() => handleEditClick(word.id)}
                    >
                      {editingWordId === word.id ? "취소" : "수정"}
                    </button>
                  </td>
                )}
              </tr>
              {editingWordId === word.id && (
                <tr className="bg-neutral-5 border-y">
                  <td colSpan="1" className="py-2 px-4"></td>
                  <td colSpan="4" className="py-2 px-4">
                    <div className="flex justify-center">
                      <label className="mr-4">
                        <input
                          type="radio"
                          value="true"
                          checked={word.correct === true}
                          onChange={() => setCorrect(true)}
                        />
                        정답
                      </label>
                      <label className="mr-4">
                        <input
                          type="radio"
                          value="false"
                          checked={word.correct === false}
                          onChange={() => setCorrect(false)}
                        />
                        오답
                      </label>
                    </div>
                  </td>
                  <td colSpan="1" className="py-2 px-4">
                    <input
                      type="text"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      placeholder="수정 이유를 입력하세요"
                      className="ml-4 p-1 border rounded w-full"
                    />
                  </td>
                  <td colSpan="1" className="py-2 px-4">
                    <button
                      className="px-4 py-1 text-blue-500 border border-blue-500"
                      onClick={() => handleSaveClick(word.id)}
                    >
                      완료
                    </button>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default StudyResult;

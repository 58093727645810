import React, { useState, useEffect, useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import UseFetchToken from "../hooks/UseFetchToken";
import { toast, ToastContainer } from "react-toastify";
import {
  Box,
  Button,
  Spinner,
  Text,
  Radio,
  Checkbox,
  Flex,
} from "@radix-ui/themes";
import { MagnifyingGlassIcon, Cross1Icon } from "@radix-ui/react-icons";

const TestPrint = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [privateOnly, setPrivateOnly] = useState(true);
  const [bookResults, setBookResults] = useState([]);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [testType, setTestType] = useState("KOREAN");
  const [words, setWords] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const searchTimeoutRef = useRef(null);
  const dropdownRef = useRef(null);
  const searchKeywordRef = useRef(searchKeyword);
  const [title, setTitle] = useState("");
  const [schoolGrade, setSchoolGrade] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const grades = [
    { label: "전체 학년", value: null },
    { label: "초등 1학년", value: 1 },
    { label: "초등 2학년", value: 2 },
    { label: "초등 3학년", value: 3 },
    { label: "초등 4학년", value: 4 },
    { label: "초등 5학년", value: 5 },
    { label: "초등 6학년", value: 6 },
    { label: "중등 1학년", value: 7 },
    { label: "중등 2학년", value: 8 },
    { label: "중등 3학년", value: 9 },
    { label: "고등 1학년", value: 10 },
    { label: "고등 2학년", value: 11 },
    { label: "고등 3학년", value: 12 },
  ];

  const fetchWords = async () => {
    if (selectedBooks.length === 0) {
      toast.error("교재를 선택해주세요.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const param = new URLSearchParams();
    selectedBooks.forEach((book) => {
      param.append("bookIds", book.id);
    });

    const data = await UseFetchToken(
      `/api/books/words/random?${param.toString()}`,
      {
        method: "GET",
      }
    );

    if (data && data.title && data.words) {
      setWords(data.words);
      setTitle(data.title);
      setShowControls(true); // Show buttons after fetching words
    } else {
      alert(
        "교재 단어를 불러오는데 실패했습니다. 새로고침 후 다시 시도해주세요."
      );
    }
  };

  const generatePDF = async () => {
    const input = document.getElementById("test-template");
    const answerInput = document.getElementById("answer-template");

    // Hide borders by changing the style
    input.style.border = "none";
    answerInput.style.border = "none";

    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Add the first page (Test)
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");

    let imgWidth = canvas.width;
    let imgHeight = canvas.height;

    // Scale the image to fit the PDF page
    if (imgWidth > pdfWidth || imgHeight > pdfHeight) {
      const scaleRatio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      imgWidth *= scaleRatio;
      imgHeight *= scaleRatio;
    }

    // Set the background color to white
    pdf.setFillColor(255, 255, 255);
    pdf.rect(0, 0, pdfWidth, pdfHeight, "F");

    // Calculate X coordinate to center the image
    const xPos = (pdfWidth - imgWidth) / 2;

    pdf.addImage(
      imgData,
      "PNG",
      xPos,
      0,
      imgWidth,
      imgHeight,
      undefined,
      "FAST"
    );

    // Add the second page (Answer)
    pdf.addPage();
    const answerCanvas = await html2canvas(answerInput);
    const answerImgData = answerCanvas.toDataURL("image/png");

    imgWidth = answerCanvas.width;
    imgHeight = answerCanvas.height;

    // Scale the image to fit the PDF page
    if (imgWidth > pdfWidth || imgHeight > pdfHeight) {
      const scaleRatio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      imgWidth *= scaleRatio;
      imgHeight *= scaleRatio;
    }

    // Set the background color to white
    pdf.setFillColor(255, 255, 255);
    pdf.rect(0, 0, pdfWidth, pdfHeight, "F");

    // Calculate X coordinate to center the image
    const answerXPos = (pdfWidth - imgWidth) / 2;

    pdf.addImage(
      answerImgData,
      "PNG",
      answerXPos,
      0,
      imgWidth,
      imgHeight,
      undefined,
      "FAST"
    );

    // Restore the original borders
    input.style.border = "";
    answerInput.style.border = "";

    // Save the PDF
    pdf.save(`${title}.pdf`);
  };

  const printPDF = async () => {
    const input = document.getElementById("test-template");
    const answerInput = document.getElementById("answer-template");

    // Hide borders by changing the style
    input.style.border = "none";
    answerInput.style.border = "none";

    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Add the first page (Test)
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");

    let imgWidth = canvas.width;
    let imgHeight = canvas.height;

    // Scale the image to fit the PDF page
    if (imgWidth > pdfWidth || imgHeight > pdfHeight) {
      const scaleRatio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      imgWidth *= scaleRatio;
      imgHeight *= scaleRatio;
    }

    // Set the background color to white
    pdf.setFillColor(255, 255, 255);
    pdf.rect(0, 0, pdfWidth, pdfHeight, "F");

    // Calculate X coordinate to center the image
    const xPos = (pdfWidth - imgWidth) / 2;

    pdf.addImage(imgData, "PNG", xPos, 0, imgWidth, imgHeight);

    // Add the second page (Answer)
    pdf.addPage();
    const answerCanvas = await html2canvas(answerInput);
    const answerImgData = answerCanvas.toDataURL("image/png");

    imgWidth = answerCanvas.width;
    imgHeight = answerCanvas.height;

    // Scale the image to fit the PDF page
    if (imgWidth > pdfWidth || imgHeight > pdfHeight) {
      const scaleRatio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      imgWidth *= scaleRatio;
      imgHeight *= scaleRatio;
    }

    // Set the background color to white
    pdf.setFillColor(255, 255, 255);
    pdf.rect(0, 0, pdfWidth, pdfHeight, "F");

    // Calculate X coordinate to center the image
    const answerXPos = (pdfWidth - imgWidth) / 2;

    pdf.addImage(answerImgData, "PNG", answerXPos, 0, imgWidth, imgHeight);

    // Restore the original borders
    input.style.border = "";
    answerInput.style.border = "";

    // Open the PDF in a new tab for printing
    const output = pdf.output("bloburl");
    const win = window.open(output, "_blank");
    win.print();
  };

  const handleBookSelect = (book) => {
    if (!selectedBooks.includes(book)) {
      setSelectedBooks([...selectedBooks, book]);
    }
  };

  const handleBookRemove = (bookId) => {
    setSelectedBooks(selectedBooks.filter((book) => book.id !== bookId));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    const searchBooks = async (keyword) => {
      setIsSearching(true);
      const params = new URLSearchParams({
        keyword: keyword,
        privateOnly: privateOnly,
      });

      if (schoolGrade !== null && !isNaN(schoolGrade)) {
        params.append("schoolGrade", schoolGrade);
      }

      const data = await UseFetchToken(`/api/books?${params.toString()}`, {
        method: "GET",
      });

      if (data) {
        setBookResults(data.books || []);
        setIsDropdownVisible(true);
        if (data.pageInfo.total > data.books.length) {
          toast.info(`최대 100건만 조회됩니다.`, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
      setIsSearching(false);
    };

    const handleDelayedSearch = (keyword) => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      setIsSearching(true);
      setBookResults([]);

      searchTimeoutRef.current = setTimeout(() => {
        searchBooks(keyword);
      }, 1000);
    };

    if (isInitialLoad) {
      setIsInitialLoad(false);
      return;
    }

    searchKeywordRef.current = searchKeyword;
    handleDelayedSearch(searchKeyword);

    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, [searchKeyword, privateOnly, schoolGrade, isInitialLoad]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getWordsInOrder = (words) => {
    const filledWords = [...words];
    while (filledWords.length < 50) {
      filledWords.push({ english: "", korean: "" });
    }

    const leftColumn = [];
    const rightColumn = [];
    filledWords.forEach((word, index) => {
      if (index % 2 === 0) {
        leftColumn.push(word);
      } else {
        rightColumn.push(word);
      }
    });
    return { leftColumn, rightColumn };
  };

  const { leftColumn, rightColumn } = getWordsInOrder(words);

  return (
    <Box maxWidth="1245px" mx="auto" mb="9">
      <ToastContainer />
      <h1 className="headline-m font-bold mt-10 mb-16">시험지 생성</h1>

      <div className="bg-neutral-5 p-5 rounded-lg space-y-4">
        <div className="flex items-center gap-5">
          <p className="font-medium">시험 유형 선택</p>
          <div className="flex space-x-4">
            <Text as="label" size="2">
              <Radio
                size="1"
                color="blue"
                name="testType"
                value="KOREAN"
                checked={testType === "KOREAN"}
                onChange={() => setTestType("KOREAN")}
              />
              <span className="ml-2">한국어 뜻 맞추기</span>
            </Text>
            <Text as="label" size="2">
              <Radio
                size="1"
                color="blue"
                name="testType"
                value="ENGLISH"
                checked={testType === "ENGLISH"}
                onChange={() => setTestType("ENGLISH")}
              />
              <span className="ml-2">영어 뜻 맞추기</span>
            </Text>
          </div>
        </div>

        <div className="relative mt-4">
          <label className="block font-medium">교재 검색</label>
          <div className="flex items-center mb-3">
            <div className="flex w-160 items-center divide-x bg-white border rounded">
              <select
                className="w-1/4 p-2"
                value={schoolGrade || ""}
                onChange={(e) =>
                  setSchoolGrade(e.target.value ? Number(e.target.value) : null)
                }
                onClick={(e) => {
                  setSchoolGrade(
                    e.target.value ? Number(e.target.value) : null
                  );
                  setIsDropdownVisible(true);
                }}
              >
                {grades.map((grade) => (
                  <option key={grade.value} value={grade.value}>
                    {grade.label}
                  </option>
                ))}
              </select>
              <div className="flex gap-1 items-center px-2 flex-1">
                <MagnifyingGlassIcon color="#3AACF5" />
                <input
                  type="text"
                  className="flex-1"
                  placeholder="교재 검색어를 입력하세요"
                  value={searchKeyword}
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                    setIsDropdownVisible(true);
                  }}
                  onClick={() => setIsDropdownVisible(true)}
                />
              </div>
            </div>
          </div>

          {isSearching && (
            <div className="w-full flex justify-center items-center mt-2">
              <Spinner />
            </div>
          )}
          {isDropdownVisible && (
            <ul
              className="absolute left-0 top-16 z-10 w-full max-h-40 p-2 overflow-y-auto bg-white border border-gray-300 rounded shadow-lg"
              ref={dropdownRef}
            >
              {bookResults.length > 0 ? (
                bookResults.map((book) => (
                  <li
                    key={book.id}
                    className="p-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleBookSelect(book)}
                  >
                    {book.publisher ? book.publisher + " - " : ""}
                    {book.name}
                    {book.chapter ? " " + book.chapter : ""}
                    {book.subject ? " " + book.subject : ""}
                  </li>
                ))
              ) : (
                <li className="p-2 text-center text-gray-500">
                  {!isSearching
                    ? "찾으시는 교재를 입력하세요."
                    : "검색 결과가 없습니다."}
                </li>
              )}
            </ul>
          )}

          <Text as="label" size="2">
            <Flex as="span" gap="2">
              <Checkbox
                size="2"
                color="blue"
                checked={privateOnly}
                onCheckedChange={(checked) => setPrivateOnly(checked)}
              />
              학생마다 다른 순서로 제출
            </Flex>
          </Text>
        </div>
      </div>

      {/* Selected books */}
      {selectedBooks.length > 0 && (
        <div className="my-4">
          <p className="font-semibold">선택된 교재</p>
          <ul className="flex flex-wrap items-center gap-2 mt-1">
            {selectedBooks.map((book) => (
              <li
                key={book.id}
                className="flex items-center text-sm py-1 px-2 bg-neutral-5"
              >
                <span className="mr-2">
                  {book.publisher ? book.publisher + " - " : ""}
                  {book.name}
                  {book.chapter ? " " + book.chapter : ""}
                  {book.subject ? " " + book.subject : ""}
                </span>
                <button
                  className="text-white bg-neutral-80 rounded"
                  onClick={() => handleBookRemove(book.id)}
                >
                  <Cross1Icon width="13" height="13" />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Load Words button */}
      <Button
        color="blue"
        size="3"
        className="w-full text-white rounded hover:bg-secondary transition-colors disabled:text-neutral-30 mt-4"
        onClick={fetchWords}
        disabled={selectedBooks.length <= 0}
      >
        시험지 생성
      </Button>

      {/* Download and Print buttons */}
      {showControls && (
        <div>
          <div className="w-44 flex ml-auto mr-0 justify-end space-x-2 mt-6">
            <Button
              className="w-1/2 bg-green-500 hover:bg-green-600"
              onClick={generatePDF}
            >
              다운로드
            </Button>
            <Button
              className="w-1/2 bg-neutral-90 hover:bg-neutral-100"
              onClick={printPDF}
            >
              인쇄
            </Button>
          </div>
          <div id="test-template" className="mt-6 p-4 bg-white border rounded">
            {/* Upper Section */}
            <div className="mb-4 border border-black bg-white">
              <div className="grid grid-cols-6 gap-0 bg-white">
                <div className="border p-2 col-span-1 text-center bg-white">
                  세트
                </div>
                <div className="border p-2 col-span-5 text-center bg-white">
                  {title}
                </div>
              </div>
              <div className="grid grid-cols-6 gap-0 bg-white">
                <div className="border p-2 col-span-1 text-center bg-white">
                  반
                </div>
                <div className="border p-2 col-span-1 bg-white"></div>
                <div className="border p-2 col-span-1 text-center bg-white">
                  날짜
                </div>
                <div className="border p-2 col-span-1 bg-white"></div>
                <div className="border p-2 col-span-1 text-center bg-white">
                  이름
                </div>
                <div className="border p-2 col-span-1 bg-white"></div>
              </div>
              <div className="grid grid-cols-6 gap-0 bg-white">
                <div className="border p-2 col-span-1 text-center bg-white">
                  메모
                </div>
                <div className="border p-2 col-span-2 bg-white"></div>
                <div className="border p-2 col-span-1 text-center bg-white">
                  점수
                </div>
                <div className="border p-2 col-span-2 bg-white"></div>
              </div>
            </div>

            {/* Lower Section */}
            <div className="border border-black bg-white">
              <table className="w-full border-collapse bg-white">
                <thead className="bg-white">
                  <tr className="bg-white">
                    <th className="border p-2 bg-white">no.</th>
                    <th className="border p-2 bg-white">
                      {testType === "KOREAN" ? "영어스펠링" : "우리말의미"}
                    </th>
                    <th className="border p-2 bg-white">
                      {testType === "KOREAN" ? "우리말의미" : "영어스펠링"}
                    </th>
                    <th className="border p-2 bg-white">no.</th>
                    <th className="border p-2 bg-white">
                      {testType === "KOREAN" ? "영어스펠링" : "우리말의미"}
                    </th>
                    <th className="border p-2 bg-white">
                      {testType === "KOREAN" ? "우리말의미" : "영어스펠링"}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {leftColumn.map((word, index) => (
                    <tr key={index} className="bg-white">
                      <td className="border p-2 text-center bg-white">
                        {index * 2 + 1}
                      </td>
                      <td className="border p-2 text-center bg-white">
                        {testType === "KOREAN" ? word.english : word.korean}
                      </td>
                      <td className="border p-2 text-center bg-white"></td>
                      <td className="border p-2 text-center bg-white">
                        {(index + 1) * 2}
                      </td>
                      <td className="border p-2 text-center bg-white">
                        {testType === "KOREAN"
                          ? rightColumn[index]?.english
                          : rightColumn[index]?.korean}
                      </td>
                      <td className="border p-2 text-center bg-white"></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div
            id="answer-template"
            className="mt-6 p-4 bg-white border rounded"
          >
            {/* Upper Section */}
            <div className="mb-4 border border-black bg-white">
              <div className="grid grid-cols-6 gap-0 bg-white">
                <div className="border p-2 col-span-1 text-center bg-white">
                  세트
                </div>
                <div className="border p-2 col-span-5 text-center bg-white">
                  {title}
                </div>
              </div>
              <div className="grid grid-cols-6 gap-0 bg-white">
                <div className="border p-2 col-span-1 text-center bg-white">
                  반
                </div>
                <div className="border p-2 col-span-1 bg-white"></div>
                <div className="border p-2 col-span-1 text-center bg-white">
                  날짜
                </div>
                <div className="border p-2 col-span-1 bg-white"></div>
                <div className="border p-2 col-span-1 text-center bg-white">
                  이름
                </div>
                <div className="border p-2 col-span-1 bg-white"></div>
              </div>
              <div className="grid grid-cols-6 gap-0 bg-white">
                <div className="border p-2 col-span-1 text-center bg-white">
                  메모
                </div>
                <div className="border p-2 col-span-2 bg-white"></div>
                <div className="border p-2 col-span-1 text-center bg-white">
                  점수
                </div>
                <div className="border p-2 col-span-2 bg-white"></div>
              </div>
            </div>

            {/* Lower Section */}
            <div className="border border-black bg-white">
              <table className="w-full border-collapse bg-white">
                <thead className="bg-white">
                  <tr className="bg-white">
                    <th className="border p-2 bg-white">no.</th>
                    <th className="border p-2 bg-white">
                      {testType === "KOREAN" ? "영어스펠링" : "우리말의미"}
                    </th>
                    <th className="border p-2 bg-white">
                      {testType === "KOREAN" ? "우리말의미" : "영어스펠링"}
                    </th>
                    <th className="border p-2 bg-white">no.</th>
                    <th className="border p-2 bg-white">
                      {testType === "KOREAN" ? "영어스펠링" : "우리말의미"}
                    </th>
                    <th className="border p-2 bg-white">
                      {testType === "KOREAN" ? "우리말의미" : "영어스펠링"}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {leftColumn.map((word, index) => (
                    <tr key={index} className="bg-white">
                      <td className="border p-2 text-center bg-white">
                        {index * 2 + 1}
                      </td>
                      <td className="border p-2 text-center bg-white">
                        {testType === "KOREAN" ? word.english : word.korean}
                      </td>
                      <td className="border p-2 text-center bg-white">
                        {testType === "KOREAN" ? word.korean : word.english}
                      </td>
                      <td className="border p-2 text-center bg-white">
                        {(index + 1) * 2}
                      </td>
                      <td className="border p-2 text-center bg-white">
                        {testType === "KOREAN"
                          ? rightColumn[index]?.english
                          : rightColumn[index]?.korean}
                      </td>
                      <td className="border p-2 text-center bg-white">
                        {testType === "KOREAN"
                          ? rightColumn[index]?.korean
                          : rightColumn[index]?.english}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default TestPrint;

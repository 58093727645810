import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import { Container, Flex, Button, DropdownMenu } from "@radix-ui/themes";
import { PersonIcon, ExitIcon, HamburgerMenuIcon } from "@radix-ui/react-icons";

function Header() {
  const { user, logout } = useContext(UserContext);
  const [nav, setNav] = useState([]);

  // 선생의 경우
  //  - 학습관리
  //    - 학습통계
  //    - 학습내역
  //    - 교재목록
  //    - 숙제등록
  //    - 시험지출력
  //  - 학원관리
  //    - 학생목록
  //    - 선생목록
  //    - 학원정보(원장님만)

  // 학생의 경우
  //  - 학습상태
  //  - 학습시작
  //  - 학습기록

  // 비회원인 경우
  //  - 이용가이드
  //  - 이용약관
  //  - 참여학원

  const location = useLocation();

  const updateNav = () => {
    let items;
    if (user.roles.includes("ROLE_DIRECTOR")) {
      items = [
        { name: "학습현황", link: "/dashboards" },
        { name: "학습내역", link: "/studies" },
        { name: "숙제등록", link: "/studies/assign" },
        { name: "시험지출력", link: "/studies/print" },
        { name: "교재목록", link: "/books" },
        { name: "학생목록", link: "/students" },
        { name: "선생목록", link: "/teachers" },
        // { name: "학원정보", link: `/academies/${user.academyId}` },
      ];
    } else if (user.roles.includes("ROLE_TEACHER")) {
      items = [
        { name: "학습현황", link: "/dashboards" },
        { name: "학습내역", link: "/studies" },
        { name: "숙제등록", link: "/studies/assign" },
        { name: "시험지출력", link: "/studies/print" },
        { name: "교재목록", link: "/books" },
        { name: "학생목록", link: "/students" },
        { name: "선생목록", link: "/teachers" },
      ];
    } else if (user.roles.includes("ROLE_STUDENT")) {
      items = [
        { name: "학습상태", link: "/dashboards" },
        { name: "학습시작", link: "/studies/new" },
        { name: "학습기록", link: "/studies" },
      ];
    } else {
      items = [
        { name: "이용가이드", link: "/tutorial" },
        { name: "이용약관", link: "/terms" },
        { name: "참여학원", link: "/academies" },
      ];
    }
    setNav(items);
  };

  useEffect(() => {
    updateNav();
  }, [user]);

  return (
    <header className="w-full px-5 sticky top-0 bg-white border-b shadow-sm z-50">
      <Container size="4" align="center">
        <Flex height="4rem" justify="between" align="center">
          <div className="flex items-center">
            <Link className="flex items-center gap-2" to="/">
              <img src="/imgs/Logo.png" alt="썬더에듀" width="110" />
            </Link>
            <nav className="max-md:hidden ml-10">
              <ul className="flex items-center gap-10">
                {nav.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={item.link}
                      className={`${
                        location.pathname === item.link
                          ? "text-secondary font-semibold"
                          : "text-neutral-100"
                      }`}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="flex items-center max-md:hidden">
            {user.isLoggedIn ? (
              <>
                {user.roles != "ROLE_STUDENT" && (
                  <Link to={"/academies/" + user.academyId}>
                    <Button
                      size="2"
                      className="bg-neutral-10 text-neutral-80 mr-2"
                    >
                      학원정보
                    </Button>
                  </Link>
                )}
                <Link to="/my-page" className="p-2">
                  <PersonIcon width="19" height="23" />
                </Link>
                <Link to="/" className="flex justify-center items-center p-2">
                  <button onClick={logout}>
                    <ExitIcon width="19" height="23" />
                  </button>
                </Link>
              </>
            ) : (
              <>
                <Link to="/sign-up" className="max-md:hidden mr-2">
                  <Button color="blue" size="3">
                    회원가입
                  </Button>
                </Link>
                <Link to="/sign-in">
                  <Button color="blue" size="3" variant="outline">
                    로그인
                  </Button>
                </Link>
              </>
            )}
          </div>

          <div className="md:hidden">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button className="bg-white text-neutral-60">
                  <HamburgerMenuIcon width="24" height="24" />
                </Button>
              </DropdownMenu.Trigger>

              <DropdownMenu.Content>
                {nav.map((item, index) => (
                  <DropdownMenu.Item key={index} className="text-lg px-8 py-4">
                    <Link
                      to={item.link}
                      className={`block w-full text-center ${
                        location.pathname === item.link
                          ? "font-semibold"
                          : "text-neutral-100"
                      }`}
                    >
                      {item.name}
                    </Link>
                  </DropdownMenu.Item>
                ))}
                <DropdownMenu.Separator />
                {user.isLoggedIn ? (
                  <>
                    <DropdownMenu.Item className="text-base px-8 py-4">
                      <Link to="/my-page" className="block w-full text-center">
                        마이페이지
                      </Link>
                    </DropdownMenu.Item>

                    <DropdownMenu.Item
                      color="red"
                      className="text-base px-8 py-4"
                    >
                      <Link to="/" className="block w-full text-center">
                        <button onClick={logout}>로그아웃</button>
                      </Link>
                    </DropdownMenu.Item>
                  </>
                ) : (
                  <>
                    <DropdownMenu.Item>
                      <Link to="/sign-up" className="max-md:hidden mr-2">
                        <Button color="blue" size="3">
                          회원가입
                        </Button>
                      </Link>
                    </DropdownMenu.Item>

                    <DropdownMenu.Item>
                      <Link to="/sign-in">
                        <Button color="blue" size="3" variant="outline">
                          로그인
                        </Button>
                      </Link>
                    </DropdownMenu.Item>
                  </>
                )}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </div>
        </Flex>
      </Container>
    </header>
  );
}

export default Header;

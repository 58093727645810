import React from "react";
import AppRoutes from "./components/AppRoutes";
import GlobalEventHandler from "./components/GlobalEventHandler";
import { UserProvider } from "./components/UserContext";

export default function App() {
  return (
    <div className="App">
      <GlobalEventHandler />
      <UserProvider>
        <AppRoutes />
      </UserProvider>
    </div>
  );
}

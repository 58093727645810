function footer() {
  return (
    <footer className="border-t p-5 bg-white">
      <div className="w-full max-w-[1248px] flex max-md:flex-col gap-5 justify-between items-center mx-auto text-xs">
        <p className="text-neutral-60">Copyright © ssunder edu 2024</p>
        <div className="flex items-center text-neutral-60 gap-4">
          <span className="bg-neutral-5 px-2 py-1 rounded">
            <b>Developer</b> psam1017@nodeblack.io
          </span>
          <span className="bg-neutral-5 px-2 py-1 rounded">
            <b>Designer</b> tngus1779@naver.com
          </span>
        </div>
      </div>
    </footer>
  );
}

export default footer;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Flex, TextField } from "@radix-ui/themes";

const HelpPw = () => {
  const navigate = useNavigate();
  const [loginId, setLoginId] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  const handlePwFind = async (e) => {
    e.preventDefault();

    if (!loginId) {
      alert("아이디를 입력해주세요.");
      return;
    }

    if (!email) {
      alert("이메일을 입력해주세요.");
      return;
    }

    if (!name) {
      alert("이름을 입력해주세요.");
      return;
    }

    setLoading(true); // Disable button

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/users/password/new`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ loginId, email, name }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        if (data.code === "200") {
          if (data.data.emailSent) {
            alert("새로운 비밀번호가 이메일로 전송되었습니다.");
            navigate("/sign-in");
          } else {
            alert("해당하는 사용자를 찾을 수 없습니다.");
          }
        } else if (data && data.data) {
          alert(data.data);
        } else {
          alert("비밀번호 조회에 실패했습니다. 다시 시도해주세요.");
        }
      } else {
        alert("비밀번호 조회에 실패했습니다. 다시 시도해주세요.");
        console.error("Failed to find login ID");
      }
    } catch (error) {
      console.error("Failed to find login PW", error);
      alert("비밀번호 생성 중 오류가 발생했습니다. 다시 시도해주세요.");
    } finally {
      setLoading(false); // Re-enable button
    }
  };

  return (
    <div className="flex h-fill items-center justify-center bg-gray-100">
      <div className="md:w-[575px] max-w-[90%] p-8 bg-white rounded-xl shadow-lg">
        <h2 className="md:text-5xl text-2xl font-bold mb-4">
          새 비밀번호 발급
        </h2>
        <form onSubmit={handlePwFind} className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block mb-1 font-bold text-neutral-50"
            >
              아이디
            </label>
            <Box>
              <TextField.Root
                size="3"
                radius="large"
                variant="surface"
                placeholder="아이디를 입력해주세요."
                autoComplete="off"
                onPaste={(e) => e.preventDefault()} // Prevent paste
                type="text"
                id="loginId"
                value={loginId}
                onChange={(e) => setLoginId(e.target.value)}
                required
                disabled={loading} // Disable input if loading
              />
            </Box>
          </div>
          <div>
            <label
              htmlFor="email"
              className="block mb-1 font-bold text-neutral-50"
            >
              이메일
            </label>
            <Box>
              <TextField.Root
                size="3"
                radius="large"
                variant="surface"
                placeholder="회원가입시 등록한 이메일을 입력해주세요."
                autoComplete="off"
                onPaste={(e) => e.preventDefault()} // Prevent paste
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={loading} // Disable input if loading
              />
            </Box>
          </div>

          <div>
            <label
              htmlFor="name"
              className="block mb-1 font-bold text-neutral-50"
            >
              이름
            </label>
            <Box>
              <TextField.Root
                size="3"
                radius="large"
                variant="surface"
                placeholder="이름을 입력해주세요."
                autoComplete="off"
                onPaste={(e) => e.preventDefault()} // Prevent paste
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                disabled={loading} // Disable input if loading
              />
            </Box>

            {errorMessage && <p className="text-warn mt-1">{errorMessage}</p>}
          </div>

          <Flex direction="column" align="stretch">
            <Button
              type="submit"
              color="blue"
              size="3"
              className={`hover:bg-secondary transition-colors${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
            >
              {loading ? "비밀번호 생성 중..." : "비밀번호 생성"}
            </Button>
          </Flex>
        </form>

        <p className="text-warn mt-4">
          *새로운 비밀번호가 이메일로 전송됩니다.
          <br />
          기존 비밀번호가 변경되니 주의하세요.
        </p>
        <p className="text-warn mt-4">
          *학생 계정은 찾을 수 없습니다.
          <br />
          다니는 학원의 선생님에게 비밀번호 변경을 요청해주세요.
        </p>

        <div className="flex flex-col gap-3 max-md:hidden mt-20">
          <Flex gap="2">
            <span className="text-neutral-50">
              다시 로그인하러 가시겠습니까?
            </span>
            <Link to="/sign-up" className="text-primary hover:underline">
              로그인 페이지로
            </Link>
          </Flex>
          <Flex gap="2">
            <span className="text-neutral-50">아이디를 잊으셨나요?</span>
            <Link to="/help/id" className="text-primary hover:underline">
              아이디 찾기
            </Link>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default HelpPw;

import React, { useEffect, useState } from "react";
import UseFetchToken from "../hooks/UseFetchToken";
import { RoleName, UserStatus } from "../constants/Constants";
import { Link } from "react-router-dom";
import { Box, Badge, Button, Flex } from "@radix-ui/themes";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";

const Teachers = () => {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({
    teacherName: "",
    status: "",
    prop: "name",
    dir: "asc",
  });
  const [inputParams, setInputParams] = useState({
    teacherName: "",
    status: "",
    prop: "name",
    dir: "asc",
  });
  const [statusDropdown, setStatusDropdown] = useState({});
  const [roleDropdown, setRoleDropdown] = useState({});

  const roles = JSON.parse(localStorage.getItem("roles"));
  const isDirector = roles && roles.includes("ROLE_DIRECTOR");
  const userId = localStorage.getItem("userId");

  const fetchTeachers = async (params) => {
    setLoading(true);

    const queryString = new URLSearchParams(params).toString();
    const data = await UseFetchToken(`/api/teachers?${queryString}`, {
      method: "GET",
    });

    if (data) {
      setTeachers(
        data.teachers.map((teacher) => {
          let teacherStatus = teacher.status;
          for (const s of UserStatus.values()) {
            if (s.name === teacher.status) {
              teacherStatus = s;
              break;
            }
          }
          const roleValue = teacher.roles.includes(RoleName.ROLE_DIRECTOR.name)
            ? RoleName.ROLE_DIRECTOR.value
            : RoleName.ROLE_TEACHER.value;

          return {
            id: teacher.id,
            name: teacher.name,
            email: teacher.email,
            phone: teacher.phone,
            street: teacher.street,
            addressDetail: teacher.addressDetail,
            postalCode: teacher.postalCode,
            statusName: teacherStatus.name,
            statusValue: teacherStatus.value,
            roles: teacher.roles,
            roleValue: roleValue,
            createdDateTime: teacher.createdDateTime,
          };
        })
      );
    }
    setLoading(false);
  };

  const updateTeacherStatus = async (teacherId, status) => {
    const response = await UseFetchToken(`/api/teachers/${teacherId}/status`, {
      method: "PATCH",
      body: JSON.stringify({ status }),
    });

    if (response) {
      setTeachers((prevTeachers) =>
        prevTeachers.map((teacher) =>
          teacher.id === teacherId
            ? {
                ...teacher,
                statusName: status,
                statusValue: UserStatus[status].value,
              }
            : teacher
        )
      );
      alert("상태가 변경되었습니다.");
    }
  };

  const updateTeacherRoles = async (teacherId, roles) => {
    const response = await UseFetchToken(`/api/teachers/${teacherId}/roles`, {
      method: "PUT",
      body: JSON.stringify({ roles }),
    });

    if (response) {
      setTeachers((prevTeachers) =>
        prevTeachers.map((teacher) =>
          teacher.id === teacherId
            ? {
                ...teacher,
                roles,
                roleValue: roles.includes(RoleName.ROLE_DIRECTOR.name)
                  ? RoleName.ROLE_DIRECTOR.value
                  : RoleName.ROLE_TEACHER.value,
              }
            : teacher
        )
      );
      alert("권한이 변경되었습니다.");
    }
  };

  useEffect(() => {
    fetchTeachers(searchParams);
  }, [searchParams]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      setStatusDropdown({});
      setRoleDropdown({});
    };

    if (isDirector) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDirector]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }

  const handleInputChange = (name, value) => {
    setInputParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchParams((prevParams) => ({
      ...prevParams,
      teacherName: inputParams.teacherName,
      status: inputParams.status,
      prop: inputParams.prop,
      dir: inputParams.dir,
    }));
  };

  const toggleStatusDropdown = (e, teacherId) => {
    e.stopPropagation();
    if (isDirector) {
      setStatusDropdown((prev) => ({
        ...prev,
        [teacherId]: !prev[teacherId],
      }));
    }
  };

  const toggleRoleDropdown = (e, teacherId) => {
    e.stopPropagation();
    if (isDirector) {
      setRoleDropdown((prev) => ({
        ...prev,
        [teacherId]: !prev[teacherId],
      }));
    }
  };

  const handleStatusChange = (teacherId, status) => {
    // 자기 자신의 상태는 변경할 수 없다
    if (teacherId === userId) {
      alert("자기 자신의 상태는 변경할 수 없습니다.");
      return;
    }

    // 바꾸려고 하는 선생이 ACTIVE 나 WITHDRAWN 이 아니면 변경할 수 없다
    const teacher = teachers.find((teacher) => teacher.id === teacherId);
    if (teacher.statusName !== "ACTIVE" && teacher.statusName !== "WITHDRAWN") {
      alert("변경할 수 없는 상태입니다.");
      return;
    }

    updateTeacherStatus(teacherId, status);
    setStatusDropdown({});
  };

  const handleRoleChange = (teacherId, role) => {
    if (teacherId === userId) {
      alert("자기 자신의 권한은 변경할 수 없습니다.");
    } else {
      const roles =
        role === RoleName.ROLE_DIRECTOR.name
          ? [RoleName.ROLE_DIRECTOR.name, RoleName.ROLE_TEACHER.name]
          : [RoleName.ROLE_TEACHER.name];
      updateTeacherRoles(teacherId, roles);
      setRoleDropdown({});
    }
  };

  return (
    <Box maxWidth="1245px" mx="auto" mb="9">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2 mt-10 mb-16">
          <Badge size="2" color="blue" className="px-4 text-base">
            선생님
          </Badge>
          <h1 className="headline-m font-bold">선생님 목록</h1>
        </div>
        <Link to={"/teachers/new"}>
          <Button size="2" color="blue" className="px-4">
            새 등록
          </Button>
        </Link>
      </div>
      <div className="max-w-full mx-auto">
        <form onSubmit={handleSearchSubmit} className="mb-4 space-y-4">
          <div className="flex justify-between items-center space-x-4">
            <div className="flex items-center space-x-2">
              <select
                name="status"
                value={inputParams.status}
                onChange={handleInputChange}
                className="p-2 border border-gray-300 rounded"
              >
                <option key="all" value="">
                  상태선택
                </option>
                {UserStatus.values().map((status) => (
                  <option key={status.name} value={status.name}>
                    {status.value}
                  </option>
                ))}
              </select>
              <select
                name="prop"
                value={inputParams.prop}
                onChange={handleInputChange}
                className="p-2 border border-gray-300 rounded"
              >
                <option value="name">정렬속성</option>
                <option value="name">이름</option>
                <option value="status">상태</option>
              </select>
              <select
                name="dir"
                value={inputParams.dir}
                onChange={handleInputChange}
                className="p-2 border border-gray-300 rounded"
              >
                <option value="asc">정렬방향</option>
                <option value="asc">오름차순</option>
                <option value="desc">내림차순</option>
              </select>

              <div className="flex gap-1 items-center pl-2 flex-1 border border-gray-300 rounded">
                <MagnifyingGlassIcon color="#3AACF5" />
                <input
                  autoComplete="off"
                  type="text"
                  name="teacherName"
                  value={inputParams.teacherName}
                  onChange={handleInputChange}
                  placeholder="이름을 입력해 주세요"
                  className="p-1 rounded"
                />
              </div>
              <Button type="submit" color="blue" className="">
                검색
              </Button>
            </div>
          </div>
        </form>

        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-neutral-5 text-neutral-70">
              <th width="20"></th>
              <th className="p-3 text-left">이름</th>
              <th className="p-3 text-left">이메일</th>
              <th className="p-3 text-left">전화번호</th>
              <th className="p-3 text-left">주소</th>
              <th className="p-3 text-left">상태</th>
              <th className="p-3 text-left">권한</th>
              <th className="p-3 text-left">가입일</th>
            </tr>
          </thead>
          <tbody>
            {teachers.map((teacher) => (
              <tr key={teacher.id} className="hover:bg-gray-100">
                <td></td>
                <td className="p-3">{teacher.name}</td>
                <td className="p-3">{teacher.email}</td>
                <td className="p-3">{teacher.phone}</td>
                <td className="p-3">
                  {teacher.street && (
                    <Flex align="center">
                      <span className="inline-block max-w-[250px] truncate">
                        {teacher.street}, {teacher.addressDetail}
                      </span>
                      <div className="text-neutral-70 rounded-full group relative cursor-default ml-2">
                        더 보기
                        <span className="absolute bottom-full mb-2 hidden w-max p-2 text-black bg-white rounded-lg shadow-lg group-hover:block">
                          {(() => {
                            const { street, addressDetail, postalCode } =
                              teacher;
                            let address = street;
                            if (addressDetail.trim()) {
                              // addressDetail에 공백을 제외한 문자열이 존재하는지 확인
                              address += `, ${addressDetail}`;
                            }
                            if (postalCode) {
                              // postalCode가 존재하는지 확인
                              address += `(${postalCode})`;
                            }
                            if (!address) {
                              address = "주소 없음";
                            }
                            return address;
                          })()}
                        </span>
                      </div>
                    </Flex>
                  )}
                </td>
                <td className="p-3">
                  <button
                    onClick={(e) => toggleStatusDropdown(e, teacher.id)}
                    className={`ml-2 p-1 rounded text-center ${
                      isDirector ? "cursor-pointer" : "cursor-not-allowed"
                    }`}
                    disabled={!isDirector}
                  >
                    <Badge
                      size="3"
                      color={
                        teacher.statusValue === "대기"
                          ? "gray"
                          : teacher.statusValue === "탈퇴"
                          ? "orange"
                          : "blue"
                      }
                    >
                      {teacher.statusValue}
                    </Badge>
                  </button>
                  {statusDropdown[teacher.id] && (
                    <div className="absolute mt-2 bg-white border rounded shadow-lg z-10 text-center">
                      {["ACTIVE", "WITHDRAWN"].map((status) => (
                        <div
                          key={status}
                          onClick={() => handleStatusChange(teacher.id, status)}
                          className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                        >
                          {UserStatus[status].value}
                        </div>
                      ))}
                    </div>
                  )}
                </td>
                <td className="p-3">
                  <button
                    onClick={(e) => toggleRoleDropdown(e, teacher.id)}
                    className={`ml-2 p-1 rounded text-center ${
                      isDirector ? "cursor-pointer" : "cursor-not-allowed"
                    }`}
                    disabled={!isDirector}
                  >
                    <Badge
                      size="3"
                      color={teacher.roleValue === "선생님" ? "blue" : "brown"}
                    >
                      {teacher.roleValue}
                    </Badge>
                  </button>
                  {roleDropdown[teacher.id] && (
                    <div className="absolute mt-2 bg-white border rounded shadow-lg z-10 text-center">
                      {["ROLE_DIRECTOR", "ROLE_TEACHER"].map((role) => (
                        <div
                          key={role}
                          onClick={() => handleRoleChange(teacher.id, role)}
                          className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                        >
                          {RoleName[role].value}
                        </div>
                      ))}
                    </div>
                  )}
                </td>
                <td className="p-3">
                  {new Date(teacher.createdDateTime).toLocaleDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

export default Teachers;

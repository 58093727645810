import React, { useState, useEffect } from "react";
import UseFetchToken from "../hooks/UseFetchToken";
import { UserStatus, RoleName } from "../constants/Constants";
import PasswordChange from "./PasswordChange";
import { toast, ToastContainer } from "react-toastify";
import { Box, Button, Flex, Spinner } from "@radix-ui/themes";
import { Cross1Icon } from "@radix-ui/react-icons";
import * as Dialog from "@radix-ui/react-dialog";
import "react-toastify/dist/ReactToastify.css";

const MyPageTeacher = () => {
  const [count, setCount] = useState(0);

  const [teacherData, setTeacherData] = useState({
    loginId: "",
    email: "",
    name: "",
    phone: "",
    street: "",
    addressDetail: "",
    postalCode: "",
    status: "",
    roles: [],
    createdDateTime: "",
  });

  const [isPhoneChecked, setIsPhoneChecked] = useState(false);
  const [phoneCheckMessage, setPhoneCheckMessage] = useState("");
  const [isCheckingPhone, setIsCheckingPhone] = useState(false);
  const [teacherValidation, setTeacherValidation] = useState({
    name: "",
    phone: "",
    postalCode: "",
  });
  const [checkTimeouts, setCheckTimeouts] = useState({
    phone: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the teacher data
    const fetchTeacher = async () => {
      setLoading(true);
      try {
        const data = await UseFetchToken("/api/users/me", {
          method: "GET",
        });
        setTeacherData(data);
      } catch (error) {
        console.error("Error fetching teacher data:", error);
      }
      setLoading(false);
    };
    fetchTeacher();
  }, [count]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }

  const phoneRegex = /^010\d{8}$/;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTeacherData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "phone" && value) {
      handleDelayedCheck(
        "phone",
        value,
        setIsPhoneChecked,
        setPhoneCheckMessage,
        setIsCheckingPhone,
        checkPhoneDuplicate,
        validateTeacherPhone
      );
    } else if (name === "name") {
      const { message } = validateTeacherName(value);
      setTeacherValidation((prevState) => ({ ...prevState, name: message }));
    } else if (name === "postalCode") {
      const { message } = validateTeacherPostalCode(value);
      setTeacherValidation((prevState) => ({
        ...prevState,
        postalCode: message,
      }));
    }
  };

  const handleDelayedCheck = (
    field,
    value,
    setIsChecked,
    setCheckMessage,
    setIsChecking,
    checkFunction,
    validateFunction
  ) => {
    setIsChecked(false);
    setCheckMessage("");
    setIsChecking(true);
    if (checkTimeouts[field]) {
      clearTimeout(checkTimeouts[field]);
    }
    if (validateFunction) {
      const validationResult = validateFunction(value);
      if (!validationResult.isValid) {
        setCheckMessage(validationResult.message);
        setIsChecked(false);
        setIsChecking(false);
        return;
      }
    }
    const timeout = setTimeout(() => checkFunction(value), 1000);
    setCheckTimeouts({
      ...checkTimeouts,
      [field]: timeout,
    });
  };

  const validateTeacherName = (name) => {
    if (!name) {
      return { isValid: false, message: "이름을 입력해주세요." };
    }
    if (!/^[가-힣a-zA-Z0-9]+$/.test(name)) {
      return {
        isValid: false,
        message: "이름은 한글, 영어, 숫자만 가능합니다.",
      };
    }
    if (name.length < 2 || name.length > 20) {
      return {
        isValid: false,
        message: "이름은 2자에서 20자 사이여야 합니다.",
      };
    }
    return { isValid: true, message: "" };
  };

  const validateTeacherPhone = (phone) => {
    if (!phone) {
      return { isValid: true, message: "" }; // Phone is optional
    }
    if (!phoneRegex.test(phone)) {
      return {
        isValid: false,
        message: "휴대폰 번호는 010으로 시작하고 11자리여야 합니다.",
      };
    }
    return { isValid: true, message: "" };
  };

  const validateTeacherPostalCode = (postalCode) => {
    if (!postalCode) {
      return { isValid: true, message: "" }; // Postal code is optional
    }
    if (!/^\d{5}$/.test(postalCode)) {
      return { isValid: false, message: "우편번호는 숫자로 5자리여야 합니다." };
    }
    return { isValid: true, message: "" };
  };

  const checkPhoneDuplicate = async (phone) => {
    const userId = localStorage.getItem("userId"); // Retrieve teacher's own userId from sessionStorage
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_HOST
        }/api/users/check-dupl?phone=${encodeURIComponent(
          phone
        )}&userId=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();

      if (result.code === "200") {
        if (result.data.isOk) {
          setPhoneCheckMessage("사용 가능한 연락처입니다.");
          setIsPhoneChecked(true);
        } else {
          setPhoneCheckMessage("이미 사용 중인 연락처입니다.");
          setIsPhoneChecked(false);
        }
      } else {
        setPhoneCheckMessage(result.message);
        setIsPhoneChecked(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setPhoneCheckMessage("연락처 중복 검사 중 에러 발생.");
      setIsPhoneChecked(false);
    } finally {
      setIsCheckingPhone(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 유효성 검사
    if (!validateTeacherName(teacherData.name).isValid) {
      alert("이름을 확인해주세요.");
      return;
    }

    if (
      !isPhoneChecked &&
      teacherData.phone &&
      !validateTeacherPhone(teacherData.phone).isValid
    ) {
      alert("휴대폰 번호를 확인해주세요.");
      return;
    }

    if (!validateTeacherPostalCode(teacherData.postalCode).isValid) {
      alert("우편번호를 확인해주세요.");
      return;
    }

    const teacherPatchData = {
      name: teacherData.name,
      phone: teacherData.phone,
      street: teacherData.street,
      addressDetail: teacherData.addressDetail,
      postalCode: teacherData.postalCode,
    };

    // 정보 업데이트
    try {
      const userId = localStorage.getItem("userId"); // Retrieve teacher's own userId from sessionStorage
      const data = await UseFetchToken(
        `/api/teachers/${userId}/personal-info`,
        {
          method: "PATCH",
          body: JSON.stringify(teacherPatchData),
        }
      );

      if (data && data.teacherId) {
        if (!toast.isActive("success", "successContainter")) {
          toast.success("개인 정보를 수정했습니다.", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            toastId: "success",
          });
        }
        setTimeout(() => {
          setCount(count + 1);
        }, 1100);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getStatusText = (status) => {
    const statusEntry = UserStatus[status];
    return statusEntry ? statusEntry.value : "알 수 없음";
  };

  const getRoleText = (roles) => {
    if (roles.includes("ROLE_DIRECTOR")) {
      return RoleName.ROLE_DIRECTOR.value;
    } else if (roles.includes("ROLE_TEACHER")) {
      return RoleName.ROLE_TEACHER.value;
    } else if (roles.includes("ROLE_STUDENT")) {
      return RoleName.ROLE_STUDENT.value;
    } else {
      return "";
    }
  };

  return (
    <Box maxWidth="1245px" mx="auto">
      <ToastContainer containerId={"successContainter"} />
      <form onSubmit={handleSubmit} className="space-y-4 p-6">
        <h2 className="md:text-5xl text-2xl font-bold mt-6 mb-10">
          마이페이지
        </h2>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">아이디</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded-md bg-gray-100">
              {teacherData.loginId}
            </p>
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">이메일</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded-md bg-gray-100">
              {teacherData.email}
            </p>
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="flex mb-2">
              이름
              <span className="inline-block w-1 h-1 bg-primary rounded-full ml-1"></span>
            </label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="name"
              value={teacherData.name}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded-md"
              placeholder="필수 입력 항목"
              required
            />
            <p
              className={`mt-2 ${
                teacherValidation.name ? "text-red-500" : "text-green-500"
              }`}
            >
              {teacherValidation.name}
            </p>
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">전화번호</label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="phone"
              value={teacherData.phone}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded-md"
            />
            {teacherData.phone &&
              (isCheckingPhone ? (
                <Spinner className="mt-2" />
              ) : (
                <p
                  className={`mt-2 ${
                    isPhoneChecked ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {phoneCheckMessage}
                </p>
              ))}
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">주소</label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="street"
              value={teacherData.street}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
            />
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">상세주소</label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="addressDetail"
              value={teacherData.addressDetail}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
            />
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">우편번호</label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="postalCode"
              value={teacherData.postalCode}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
            />
            <p
              className={`mt-2 ${
                teacherValidation.postalCode ? "text-red-500" : "text-green-500"
              }`}
            >
              {teacherValidation.postalCode}
            </p>
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">회원 상태</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded bg-gray-100">
              {getStatusText(teacherData.status)}
            </p>
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">권한</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded bg-gray-100">
              {getRoleText(teacherData.roles)}
            </p>
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">가입일</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded bg-gray-100">
              {(() => {
                const date = new Date(teacherData.createdDateTime);
                const year = date.getFullYear();
                const month = ("0" + (date.getMonth() + 1)).slice(-2); // 2자리로 맞추기 위해 '0'을 붙이고 slice 사용
                const day = ("0" + date.getDate()).slice(-2); // 2자리로 맞추기 위해 '0'을 붙이고 slice 사용
                return `${year}년 ${month}월 ${day}일`;
              })()}
            </p>
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">비밀번호 변경</label>
          </Box>

          <Box width="360px">
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <Button
                  type="button"
                  color="blue"
                  size="3"
                  className="hover:bg-secondary transition-colors"
                >
                  변경
                </Button>
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay className="DialogOverlay" />
                <Dialog.Content className="DialogContent">
                  <Dialog.Title className="headline-m">
                    <b>비밀번호 변경</b>
                  </Dialog.Title>
                  <PasswordChange />
                  <Dialog.Close asChild>
                    <button className="IconButton" aria-label="Close">
                      <Cross1Icon />
                    </button>
                  </Dialog.Close>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          </Box>
        </Flex>
        <div className="flex">
          <Button
            type="submit"
            color="blue"
            size="3"
            className="hover:bg-secondary transition-colors mt-10"
          >
            정보 수정
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default MyPageTeacher;

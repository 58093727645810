import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import UseFetchToken from "../hooks/UseFetchToken";
import { UserStatus } from "../constants/Constants";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Button, Flex, Spinner } from "@radix-ui/themes";

const Student = () => {
  const [loading, setLoading] = useState(true);
  const { studentId } = useParams();
  const isEditing = Boolean(studentId);
  const [count, setCount] = useState(0);

  const [studentData, setStudentData] = useState({
    loginId: "",
    loginPw: "",
    name: "",
    phone: "",
    street: "",
    addressDetail: "",
    postalCode: "",
    attendanceId: "",
    note: "",
    schoolName: "",
    schoolGrade: "",
    parentName: "",
    parentPhone: "",
  });

  const [status, setStatus] = useState("");
  const [isLoginIdChecked, setIsLoginIdChecked] = useState(false);
  const [loginIdCheckMessage, setLoginIdCheckMessage] = useState(
    isEditing ? "" : "아이디를 입력해주세요."
  );
  const [isCheckingLoginId, setIsCheckingLoginId] = useState(false);
  const [isPhoneChecked, setIsPhoneChecked] = useState(
    isEditing && studentData.phone !== ""
  );
  const [phoneCheckMessage, setPhoneCheckMessage] = useState(
    isEditing ? "" : "휴대폰 번호를 입력해주세요."
  );
  const [isCheckingPhone, setIsCheckingPhone] = useState(false);
  const [isAttendanceIdChecked, setIsAttendanceIdChecked] = useState(
    isEditing && studentData.attendanceId !== ""
  );
  const [attendanceIdCheckMessage, setAttendanceIdCheckMessage] = useState("");
  const [isCheckingAttendanceId, setIsCheckingAttendanceId] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordConfirmMessage, setPasswordConfirmMessage] = useState(
    isEditing ? "" : "비밀번호를 한 번 더 입력해주세요."
  );
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false);
  const [studentValidation, setStudentValidation] = useState({
    loginId: isEditing ? "" : "아이디를 입력해주세요.",
    loginPw: isEditing ? "" : "비밀번호를 입력해주세요.",
    name: isEditing ? "" : "이름을 입력해주세요.",
    phone: isEditing ? "" : "휴대폰 번호를 입력해주세요.",
    postalCode: "",
    parentPhone: "",
  });
  const [checkTimeouts, setCheckTimeouts] = useState({
    loginId: null,
    phone: null,
    attendanceId: null,
  });

  useEffect(() => {
    // Fetch the student data for editing
    const fetchStudent = async () => {
      setLoading(true);
      if (isEditing) {
        try {
          const data = await UseFetchToken(`/api/students/${studentId}`, {
            method: "GET",
          });
          setStudentData(data);
          setStatus(data.status);
        } catch (error) {
          console.error("Error fetching student:", error);
        }
      }
      setLoading(false);
    };
    fetchStudent();
  }, [isEditing, studentId, count]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }

  const passwordRegex =
    /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
  const phoneRegex = /^010\d{8}$/;
  const parentPhoneRegex = /^[0-9]{8,12}$/;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "loginId" && !isEditing) {
      handleDelayedCheck(
        "loginId",
        value,
        setIsLoginIdChecked,
        setLoginIdCheckMessage,
        setIsCheckingLoginId,
        checkLoginIdDuplicate,
        validateStudentLoginId
      );
    } else if (name === "loginPw") {
      const { message } = validateStudentPassword(value);
      setStudentValidation((prevState) => ({ ...prevState, loginPw: message }));
    } else if (name === "name") {
      const { message } = validateStudentName(value);
      setStudentValidation((prevState) => ({ ...prevState, name: message }));
    } else if (name === "phone" && value) {
      handleDelayedCheck(
        "phone",
        value,
        setIsPhoneChecked,
        setPhoneCheckMessage,
        setIsCheckingPhone,
        checkPhoneDuplicate,
        validateStudentPhone
      );
    } else if (name === "loginPwConfirm") {
      setPasswordConfirm(value);
      const { isValid, message } = validatePasswordConfirm(
        value,
        studentData.loginPw
      );
      setPasswordConfirmMessage(message);
      setIsPasswordConfirmed(isValid);
    } else if (name === "postalCode") {
      const { message } = validateStudentPostalCode(value);
      setStudentValidation((prevState) => ({
        ...prevState,
        postalCode: message,
      }));
    } else if (name === "attendanceId" && value) {
      handleDelayedCheck(
        "attendanceId",
        value,
        setIsAttendanceIdChecked,
        setAttendanceIdCheckMessage,
        setIsCheckingAttendanceId,
        checkAttendanceIdDuplicate,
        validateAttendanceId
      );
    } else if (name === "parentPhone") {
      const { message } = validateParentPhone(value);
      setStudentValidation((prevState) => ({
        ...prevState,
        parentPhone: message,
      }));
    }
  };

  const handleDelayedCheck = (
    field,
    value,
    setIsChecked,
    setCheckMessage,
    setIsChecking,
    checkFunction,
    validateFunction
  ) => {
    setIsChecked(false);
    setCheckMessage("");
    setIsChecking(true);
    if (checkTimeouts[field]) {
      clearTimeout(checkTimeouts[field]);
    }
    if (validateFunction) {
      const validationResult = validateFunction(value);
      if (!validationResult.isValid) {
        setCheckMessage(validationResult.message);
        setIsChecked(false);
        setIsChecking(false);
        return;
      }
    }
    const timeout = setTimeout(() => checkFunction(value), 1000);
    setCheckTimeouts({
      ...checkTimeouts,
      [field]: timeout,
    });
  };

  const validateStudentLoginId = (loginId) => {
    if (!loginId) {
      return { isValid: false, message: "로그인 아이디를 입력해주세요." };
    }
    if (!/^[a-zA-Z0-9]{6,20}$/.test(loginId)) {
      return {
        isValid: false,
        message: "로그인 아이디는 영어와 숫자로 6자에서 20자 사이여야 합니다.",
      };
    }
    return { isValid: true, message: "" };
  };

  const validateStudentPassword = (password) => {
    if (!password) {
      return {
        isValid: isEditing,
        message: isEditing ? "" : "비밀번호를 입력해주세요.",
      };
    }
    if (!passwordRegex.test(password)) {
      return {
        isValid: false,
        message:
          "비밀번호는 8자에서 20자 사이여야 하며, 문자, 숫자, 특수문자를 포함해야 합니다.",
      };
    }
    return { isValid: true, message: "" };
  };

  const validatePasswordConfirm = (confirmPassword, originalPassword) => {
    if (!confirmPassword) {
      return {
        isValid: isEditing,
        message: isEditing ? "" : "비밀번호를 한 번 더 입력해주세요.",
      };
    }
    if (confirmPassword !== originalPassword) {
      return { isValid: false, message: "비밀번호가 일치하지 않습니다." };
    }
    return { isValid: true, message: "비밀번호가 일치합니다." };
  };

  const validateStudentName = (name) => {
    if (!name) {
      return { isValid: false, message: "이름을 입력해주세요." };
    }
    if (!/^[가-힣a-zA-Z0-9]+$/.test(name)) {
      return {
        isValid: false,
        message: "이름은 한글, 영어, 숫자만 가능합니다.",
      };
    }
    if (name.length < 2 || name.length > 20) {
      return {
        isValid: false,
        message: "이름은 2자에서 20자 사이여야 합니다.",
      };
    }
    return { isValid: true, message: "" };
  };

  const validateStudentPhone = (phone) => {
    if (!phone) {
      return { isValid: true, message: "" }; // Phone is optional
    }
    if (!phoneRegex.test(phone)) {
      return {
        isValid: false,
        message: "휴대폰 번호는 010으로 시작하고 11자리여야 합니다.",
      };
    }
    return { isValid: true, message: "" };
  };

  const validateAttendanceId = (attendanceId) => {
    if (!attendanceId) {
      return { isValid: true, message: "" }; // Attendance ID is optional
    }
    // 출석아이디는 1자 ~ 127자만 허용
    if (attendanceId.length < 1 || attendanceId.length > 127) {
      return {
        isValid: false,
        message: "출석번호는 1자에서 127자 사이여야 합니다.",
      };
    }
    return { isValid: true, message: "" };
  };

  const validateStudentPostalCode = (postalCode) => {
    if (!postalCode) {
      return { isValid: true, message: "" }; // Postal code is optional
    }
    if (!/^\d{5}$/.test(postalCode)) {
      return { isValid: false, message: "우편번호는 숫자로 5자리여야 합니다." };
    }
    return { isValid: true, message: "" };
  };

  const validateParentPhone = (phone) => {
    if (!parentPhoneRegex.test(phone)) {
      return {
        isValid: false,
        message: "부모 연락처는 숫자로 8자리에서 12자리여야 합니다.",
      };
    }
    return { isValid: true, message: "" };
  };

  const checkLoginIdDuplicate = async (loginId) => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_HOST
        }/api/users/check-dupl?loginId=${encodeURIComponent(loginId)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();

      if (result.code === "200") {
        if (result.data.isOk) {
          setLoginIdCheckMessage("사용 가능한 아이디입니다.");
          setIsLoginIdChecked(true);
        } else {
          setLoginIdCheckMessage("이미 사용 중인 아이디입니다.");
          setIsLoginIdChecked(false);
        }
      } else {
        setLoginIdCheckMessage(result.message);
        setIsLoginIdChecked(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoginIdCheckMessage("아이디 중복 검사 중 에러 발생.");
      setIsLoginIdChecked(false);
    } finally {
      setIsCheckingLoginId(false);
    }
  };

  const checkPhoneDuplicate = async (phone) => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_HOST
        }/api/users/check-dupl?phone=${encodeURIComponent(phone)}${
          isEditing ? `&userId=${studentId}` : ""
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();

      if (result.code === "200") {
        if (result.data.isOk) {
          setPhoneCheckMessage("사용 가능한 연락처입니다.");
          setIsPhoneChecked(true);
        } else {
          setPhoneCheckMessage("이미 사용 중인 연락처입니다.");
          setIsPhoneChecked(false);
        }
      } else {
        setPhoneCheckMessage(result.message);
        setIsPhoneChecked(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setPhoneCheckMessage("연락처 중복 검사 중 에러 발생.");
      setIsPhoneChecked(false);
    } finally {
      setIsCheckingPhone(false);
    }
  };

  const checkAttendanceIdDuplicate = async (attendanceId) => {
    try {
      const data = await UseFetchToken(
        `/api/students/check-dupl?attendanceId=${encodeURIComponent(
          attendanceId
        )}${isEditing ? `&studentId=${studentId}` : ""}`,
        {
          method: "GET",
        }
      );

      if (data) {
        if (data.isOk) {
          setAttendanceIdCheckMessage("사용 가능한 출석번호입니다.");
          setIsAttendanceIdChecked(true);
        } else {
          setAttendanceIdCheckMessage("이미 사용 중인 출석번호입니다.");
          setIsAttendanceIdChecked(false);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setAttendanceIdCheckMessage("출석번호 중복 검사 중 에러 발생.");
      setIsAttendanceIdChecked(false);
    } finally {
      setIsCheckingAttendanceId(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 학생 정보 유효성 검사
    const requiredFields = [
      { value: studentData.loginId, message: "로그인 아이디를 입력해주세요." },
      { value: studentData.name, message: "이름을 입력해주세요." },
    ];

    if (!isEditing) {
      requiredFields.push({
        value: studentData.loginPw,
        message: "비밀번호를 입력해주세요.",
      });
    }

    for (const field of requiredFields) {
      if (!field.value) {
        alert(field.message);
        return;
      }
    }

    if (!validateStudentLoginId(studentData.loginId).isValid && !isEditing) {
      alert("로그인 아이디를 확인해주세요.");
      return;
    }

    if (!validateStudentPassword(studentData.loginPw).isValid) {
      alert("비밀번호를 확인해주세요.");
      return;
    }

    if (!isPasswordConfirmed && !isEditing) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    if (!validateStudentName(studentData.name).isValid) {
      alert("학생 이름을 확인해주세요.");
      return;
    }

    if (
      !isPhoneChecked &&
      studentData.phone &&
      !validateStudentPhone(studentData.phone).isValid
    ) {
      alert("학생 휴대폰 번호를 확인해주세요.");
      return;
    }

    if (!validateStudentPostalCode(studentData.postalCode).isValid) {
      alert("학생 우편번호를 확인해주세요.");
      return;
    }

    if (!validateParentPhone(studentData.parentPhone).isValid) {
      alert("부모 연락처를 확인해주세요.");
      return;
    }

    // 학생 정보 중복 검사
    if (!isLoginIdChecked && !isEditing) {
      alert("로그인 아이디 중복 검사를 완료해주세요.");
      return;
    }

    if (
      !isAttendanceIdChecked &&
      studentData.attendanceId &&
      !validateAttendanceId(studentData.attendanceId).isValid
    ) {
      alert("출석번호 중복 검사를 완료해주세요.");
      return;
    }

    const method = isEditing ? "PATCH" : "POST";
    const url = isEditing
      ? `/api/students/${studentId}/personal-info`
      : "/api/students";
    const formData = isEditing
      ? {
          loginPw: studentData.loginPw,
          name: studentData.name,
          phone: studentData.phone,
          street: studentData.street,
          addressDetail: studentData.addressDetail,
          postalCode: studentData.postalCode ? studentData.postalCode : null,
          attendanceId: studentData.attendanceId,
          note: studentData.note,
          schoolName: studentData.schoolName,
          schoolGrade: studentData.schoolGrade,
          parentName: studentData.parentName,
          parentPhone: studentData.parentPhone,
        }
      : {
          loginId: studentData.loginId,
          loginPw: studentData.loginPw,
          name: studentData.name,
          phone: studentData.phone,
          street: studentData.street,
          addressDetail: studentData.addressDetail,
          postalCode: studentData.postalCode,
          attendanceId: studentData.attendanceId,
          note: studentData.note,
          schoolName: studentData.schoolName,
          schoolGrade: studentData.schoolGrade,
          parentName: studentData.parentName,
          parentPhone: studentData.parentPhone,
        };

    try {
      const data = await UseFetchToken(url, {
        method,
        body: JSON.stringify(formData),
      });

      if (data) {
        toast.success(
          isEditing ? "학생 정보를 수정했습니다." : "학생을 등록했습니다.",
          {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            toastId: "success",
          }
        );
        setCount(count + 1);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleStatusChange = async (e) => {
    e.preventDefault();

    if (!status) {
      alert("상태를 선택해주세요.");
      return;
    }

    try {
      const data = await UseFetchToken(`/api/students/${studentId}/status`, {
        method: "PATCH",
        body: JSON.stringify({ status }),
      });

      if (data) {
        toast.success("상태가 성공적으로 변경되었습니다.", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          toastId: "success",
        });
        setCount(count + 1);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const renderGradeOptions = () => {
    const grades = [
      { label: "초등1", value: 1 },
      { label: "초등2", value: 2 },
      { label: "초등3", value: 3 },
      { label: "초등4", value: 4 },
      { label: "초등5", value: 5 },
      { label: "초등6", value: 6 },
      { label: "중등1", value: 7 },
      { label: "중등2", value: 8 },
      { label: "중등3", value: 9 },
      { label: "고등1", value: 10 },
      { label: "고등2", value: 11 },
      { label: "고등3", value: 12 },
    ];

    return grades.map((grade) => (
      <option key={grade.value} value={grade.value}>
        {grade.label}
      </option>
    ));
  };

  const renderStatusOptions = () => {
    const statuses = [
      UserStatus.PENDING,
      UserStatus.ACTIVE,
      UserStatus.WITHDRAWN,
    ];

    return statuses.map((status) => (
      <option key={status.name} value={status.name}>
        {status.value}
      </option>
    ));
  };

  return (
    <Box maxWidth="1245px" mx="auto" mb="9">
      <ToastContainer />
      <form onSubmit={handleSubmit} className="space-y-4">
        <h1 className="headline-m font-bold mt-10 mb-16">
          {isEditing ? "학생 수정" : "학생 등록"}
        </h1>

        <Flex>
          <Box width="100px" mt="2">
            <label className="flex mb-2">
              로그인 아이디
              <span className="inline-block w-1 h-1 bg-primary rounded-full ml-1"></span>
            </label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="loginId"
              value={studentData.loginId}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
              placeholder="필수 입력 항목"
              required
              disabled={isEditing}
            />
            {!isEditing && isCheckingLoginId && (
              <p className="mt-1 text-blue-500">
                <Spinner />
              </p>
            )}
            {!isEditing && !isCheckingLoginId && (
              <p
                className={`mt-1 ${
                  isLoginIdChecked ? "text-green-500" : "text-red-500"
                }`}
              >
                {loginIdCheckMessage}
              </p>
            )}
          </Box>
        </Flex>

        <Flex>
          <Box width="100px" mt="2">
            <label className="flex mb-2">
              비밀번호
              {!isEditing && (
                <span className="inline-block w-1 h-1 bg-primary rounded-full ml-1"></span>
              )}
            </label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="password"
              name="loginPw"
              value={studentData.loginPw}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
              placeholder={!isEditing ? "필수 입력 항목" : ""}
              required={!isEditing}
            />
            {!isEditing && (
              <p
                className={`mt-2 ${
                  studentValidation.loginPw ? "text-red-500" : "text-green-500"
                }`}
              >
                {studentValidation.loginPw}
              </p>
            )}
          </Box>
        </Flex>

        <Flex>
          <Box width="100px" mt="2">
            <label className="flex mb-2">
              비밀번호 확인
              {!isEditing && (
                <span className="inline-block w-1 h-1 bg-primary rounded-full ml-1"></span>
              )}
            </label>
          </Box>
          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="password"
              name="loginPwConfirm"
              value={passwordConfirm}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
              placeholder={!isEditing ? "필수 입력 항목" : ""}
              required={!isEditing}
            />
            {!isEditing && (
              <p
                className={`mt-2 ${
                  isPasswordConfirmed ? "text-green-500" : "text-red-500"
                }`}
              >
                {passwordConfirmMessage}
              </p>
            )}
          </Box>
        </Flex>

        <Flex>
          <Box width="100px" mt="2">
            <label className="flex mb-2">
              이름
              <span className="inline-block w-1 h-1 bg-primary rounded-full ml-1"></span>
            </label>
          </Box>
          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="name"
              value={studentData.name}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
              placeholder="필수 입력 항목"
              required
            />
            <p
              className={`mt-2 ${
                studentValidation.name ? "text-red-500" : "text-green-500"
              }`}
            >
              {studentValidation.name}
            </p>
          </Box>
        </Flex>

        <Flex>
          <Box width="100px" mt="2">
            <label className="block mb-2">연락처</label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="phone"
              value={studentData.phone}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
            />
            {studentData.phone &&
              (isCheckingPhone ? (
                <p className="mt-2 text-blue-500">중복 검사 중...</p>
              ) : (
                <p
                  className={`mt-2 ${
                    isPhoneChecked ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {phoneCheckMessage}
                </p>
              ))}
          </Box>
        </Flex>

        <Flex>
          <Box width="100px" mt="2">
            <label className="block mb-2">주소</label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="street"
              value={studentData.street}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
            />
          </Box>
        </Flex>

        <Flex>
          <Box width="100px" mt="2">
            <label className="block mb-2">상세주소</label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="addressDetail"
              value={studentData.addressDetail}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
            />
          </Box>
        </Flex>

        <Flex>
          <Box width="100px" mt="2">
            <label className="block mb-2">우편번호</label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="postalCode"
              value={studentData.postalCode}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
            />
            <p
              className={`mt-2 ${
                studentValidation.postalCode ? "text-red-500" : "text-green-500"
              }`}
            >
              {studentValidation.postalCode}
            </p>
          </Box>
        </Flex>

        <Flex>
          <Box width="100px" mt="2">
            <label className="block mb-2">출석번호</label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="attendanceId"
              value={studentData.attendanceId}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
            />
            {studentData.attendanceId &&
              (isCheckingAttendanceId ? (
                <p className="mt-2 text-blue-500">중복 검사 중...</p>
              ) : (
                <p
                  className={`mt-2 ${
                    isAttendanceIdChecked ? "text-green-500" : "text-red-500"
                  }`}
                >
                  {attendanceIdCheckMessage}
                </p>
              ))}
          </Box>
        </Flex>

        <Flex>
          <Box width="100px" mt="2">
            <label className="block mb-2">학교 이름</label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="schoolName"
              value={studentData.schoolName}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
            />
          </Box>
        </Flex>

        <Flex>
          <Box width="100px" mt="2">
            <label className="block mb-2">학년</label>
          </Box>

          <Box width="360px">
            <select
              name="schoolGrade"
              value={studentData.schoolGrade}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
            >
              <option value="">학년 선택</option>
              {renderGradeOptions()}
            </select>
          </Box>
        </Flex>

        <Flex>
          <Box width="100px" mt="2">
            <label className="block mb-2">부모 이름</label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="parentName"
              value={studentData.parentName}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
            />
          </Box>
        </Flex>

        <Flex>
          <Box width="100px" mt="2">
            <label className="block mb-2">부모 연락처</label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="parentPhone"
              value={studentData.parentPhone}
              onChange={handleInputChange}
              className="block w-full p-2 border border-gray-300 rounded"
              pattern="[0-9]{8,12}"
            />
            <p
              className={`mt-2 ${
                studentValidation.parentPhone
                  ? "text-red-500"
                  : "text-green-500"
              }`}
            >
              {studentValidation.parentPhone}
            </p>
          </Box>
        </Flex>
        <Flex>
          <Box width="100px" mt="2">
            <label className="block mb-2">특이사항</label>
          </Box>

          <Box width="100%">
            <textarea
              name="note"
              value={studentData.note}
              onChange={handleInputChange}
              className="block w-full h-44 p-2 border border-gray-300 rounded"
              rows="5"
              style={{ resize: "none" }}
            />
          </Box>
        </Flex>
      </form>
      {isEditing && (
        <form onSubmit={handleStatusChange} className="mt-5">
          <Flex>
            <Box width="100px" mt="2">
              <label className="block mb-2">학생 상태</label>
            </Box>

            <Flex width="100%" align="center">
              <Box width="360px">
                <select
                  name="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="block w-full p-2 border border-gray-300 rounded"
                >
                  <option value="">상태 선택</option>
                  {renderStatusOptions()}
                </select>
              </Box>

              <Button type="submit" color="blue" size="3" className="ml-1 px-6">
                변경
              </Button>
            </Flex>
          </Flex>
        </form>
      )}

      <Button type="submit" size="3" color="blue" className="mt-10 px-6">
        {isEditing ? "수정" : "등록"}
      </Button>
    </Box>
  );
};

export default Student;

import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Separator,
  Switch,
  Spinner,
  Text,
} from "@radix-ui/themes";

const SignUp = () => {
  const [academy, setAcademy] = useState({
    name: "",
    phone: null,
    email: "",
    street: null,
    addressDetail: null,
    postalCode: null,
    openToPublic: false,
  });

  const [director, setDirector] = useState({
    loginId: "",
    loginPw: "",
    name: "",
    email: "",
    phone: null,
    street: null,
    addressDetail: null,
    postalCode: null,
  });

  const [isNameChecked, setIsNameChecked] = useState(false);
  const [nameCheckMessage, setNameCheckMessage] = useState("");
  const [isCheckingName, setIsCheckingName] = useState(false);

  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [emailCheckMessage, setEmailCheckMessage] = useState("");
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);

  const [isDirectorIdChecked, setIsDirectorIdChecked] = useState(false);
  const [directorIdCheckMessage, setDirectorIdCheckMessage] = useState("");
  const [isCheckingDirectorId, setIsCheckingDirectorId] = useState(false);

  const [isDirectorEmailChecked, setIsDirectorEmailChecked] = useState(false);
  const [directorEmailCheckMessage, setDirectorEmailCheckMessage] =
    useState("");
  const [isCheckingDirectorEmail, setIsCheckingDirectorEmail] = useState(false);

  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordConfirmMessage, setPasswordConfirmMessage] = useState("");
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false);

  const [directorValidation, setDirectorValidation] = useState({
    loginId: "",
    loginPw: "",
    name: "",
    email: "",
  });

  const [checkTimeouts, setCheckTimeouts] = useState({
    name: null,
    email: null,
    phone: null,
    directorId: null,
    directorEmail: null,
    directorPhone: null,
  });

  const emailRegex =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  const passwordRegex =
    /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;

  const handleInputChange = (e, type) => {
    const { name, value, type: inputType, checked } = e.target;
    if (type === "academy") {
      setAcademy((prevState) => ({
        ...prevState,
        [name]: inputType === "checkbox" ? checked : value,
      }));

      if (name === "name") {
        handleDelayedCheck(
          "name",
          value,
          setIsNameChecked,
          setNameCheckMessage,
          setIsCheckingName,
          checkNameDuplicate,
          validateAcademyName
        );
      } else if (name === "email") {
        handleDelayedCheck(
          "email",
          value,
          setIsEmailChecked,
          setEmailCheckMessage,
          setIsCheckingEmail,
          checkEmailDuplicate,
          validateAcademyEmail
        );
      }
    } else if (type === "director") {
      setDirector((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      if (name === "loginId") {
        handleDelayedCheck(
          "directorId",
          value,
          setIsDirectorIdChecked,
          setDirectorIdCheckMessage,
          setIsCheckingDirectorId,
          checkDirectorIdDuplicate,
          validateDirectorLoginId
        );
      } else if (name === "loginPw") {
        const { message } = validateDirectorPassword(value);
        setDirectorValidation((prevState) => ({
          ...prevState,
          loginPw: message,
        }));
      } else if (name === "loginPwConfirm") {
        setPasswordConfirm(value);
        const { isValid, message } = validatePasswordConfirm(
          value,
          director.loginPw
        );
        setPasswordConfirmMessage(message);
        setIsPasswordConfirmed(isValid);
      } else if (name === "name") {
        const { message } = validateDirectorName(value);
        setDirectorValidation((prevState) => ({ ...prevState, name: message }));
      } else if (name === "email") {
        handleDelayedCheck(
          "directorEmail",
          value,
          setIsDirectorEmailChecked,
          setDirectorEmailCheckMessage,
          setIsCheckingDirectorEmail,
          checkDirectorEmailDuplicate,
          validateDirectorEmail
        );
      }
    }
  };

  const handleDelayedCheck = (
    field,
    value,
    setIsChecked,
    setCheckMessage,
    setIsChecking,
    checkFunction,
    validateFunction
  ) => {
    setIsChecked(false);
    setCheckMessage("");
    setIsChecking(true);
    if (checkTimeouts[field]) {
      clearTimeout(checkTimeouts[field]);
    }
    if (validateFunction) {
      const validationResult = validateFunction(value);
      if (!validationResult.isValid) {
        setCheckMessage(validationResult.message);
        setIsChecked(false);
        setIsChecking(false);
        return;
      }
    }
    const timeout = setTimeout(() => checkFunction(value), 1000);
    setCheckTimeouts({
      ...checkTimeouts,
      [field]: timeout,
    });
  };

  const validateAcademyName = (name) => {
    if (!name) {
      return { isValid: false, message: "학원 이름을 입력해주세요." };
    }
    if (!/^[가-힣]+$/.test(name)) {
      return { isValid: false, message: "학원 이름은 한글만 가능합니다." };
    }
    if (name.length < 2 || name.length > 30) {
      return {
        isValid: false,
        message: "학원 이름은 2자에서 30자 사이여야 합니다.",
      };
    }
    return { isValid: true, message: "" };
  };

  const validateAcademyEmail = (email) => {
    if (!email) {
      return { isValid: false, message: "이메일을 입력해주세요." };
    }
    if (!emailRegex.test(email)) {
      return { isValid: false, message: "유효한 이메일 형식이 아닙니다." };
    }
    return { isValid: true, message: "" };
  };

  const validateDirectorLoginId = (loginId) => {
    if (!loginId) {
      return { isValid: false, message: "로그인 아이디를 입력해주세요." };
    }
    if (!/^[a-zA-Z0-9]{6,20}$/.test(loginId)) {
      return {
        isValid: false,
        message: "로그인 아이디는 영어와 숫자로 6자에서 20자 사이여야 합니다.",
      };
    }
    return { isValid: true, message: "" };
  };

  const validateDirectorPassword = (password) => {
    if (!password) {
      return { isValid: false, message: "비밀번호를 입력해주세요." };
    }
    if (!passwordRegex.test(password)) {
      return {
        isValid: false,
        message:
          "비밀번호는 8자에서 20자 사이여야 하며, 문자, 숫자, 특수문자를 포함해야 합니다.",
      };
    }
    return { isValid: true, message: "" };
  };

  const validatePasswordConfirm = (confirmPassword, originalPassword) => {
    if (!confirmPassword) {
      return { isValid: false, message: "비밀번호를 한 번 더 입력해주세요." };
    }
    if (confirmPassword !== originalPassword) {
      return { isValid: false, message: "비밀번호가 일치하지 않습니다." };
    }
    return { isValid: true, message: "비밀번호가 일치합니다." };
  };

  const validateDirectorName = (name) => {
    if (!name) {
      return { isValid: false, message: "이름을 입력해주세요." };
    }
    if (!/^[가-힣a-zA-Z0-9]+$/.test(name)) {
      return {
        isValid: false,
        message: "이름은 한글, 영어, 숫자만 가능합니다.",
      };
    }
    if (name.length < 2 || name.length > 20) {
      return {
        isValid: false,
        message: "이름은 2자에서 20자 사이여야 합니다.",
      };
    }
    return { isValid: true, message: "" };
  };

  const validateDirectorEmail = (email) => {
    if (!email) {
      return { isValid: false, message: "이메일을 입력해주세요." };
    }
    if (!emailRegex.test(email)) {
      return { isValid: false, message: "유효한 이메일 형식이 아닙니다." };
    }
    return { isValid: true, message: "" };
  };

  const checkNameDuplicate = async (name) => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_HOST
        }/api/academies/check-dupl?name=${encodeURIComponent(name)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();

      if (result.code === "200") {
        if (result.data.isOk) {
          setNameCheckMessage("사용 가능한 학원 이름입니다.");
          setIsNameChecked(true);
        } else {
          setNameCheckMessage("이미 사용 중인 학원 이름입니다.");
          setIsNameChecked(false);
        }
      } else if (result.code.charAt(0) === "0") {
        setNameCheckMessage(result.data);
        setIsNameChecked(false);
      } else {
        console.error(result);
        console.error(result.reasons);
      }
    } catch (error) {
      console.error("Error:", error);
      setNameCheckMessage("이름 중복 검사 중 에러 발생.");
      setIsNameChecked(false);
    } finally {
      setIsCheckingName(false);
    }
  };

  const checkEmailDuplicate = async (email) => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_HOST
        }/api/academies/check-dupl?email=${encodeURIComponent(email)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();

      if (result.code === "200") {
        if (result.data.isOk) {
          setEmailCheckMessage("사용 가능한 이메일입니다.");
          setIsEmailChecked(true);
        } else {
          setEmailCheckMessage("이미 사용 중인 이메일입니다.");
          setIsEmailChecked(false);
        }
      } else if (result.code.charAt(0) === "0") {
        setEmailCheckMessage(result.data);
        setIsEmailChecked(false);
      } else {
        console.error(result);
        console.error(result.reasons);
      }
    } catch (error) {
      console.error("Error:", error);
      setEmailCheckMessage("이메일 중복 검사 중 에러 발생.");
      setIsEmailChecked(false);
    } finally {
      setIsCheckingEmail(false);
    }
  };

  const checkDirectorIdDuplicate = async (loginId) => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_HOST
        }/api/users/check-dupl?loginId=${encodeURIComponent(loginId)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();

      if (result.code === "200") {
        if (result.data.isOk) {
          setDirectorIdCheckMessage("사용 가능한 아이디입니다.");
          setIsDirectorIdChecked(true);
        } else {
          setDirectorIdCheckMessage("이미 사용 중인 아이디입니다.");
          setIsDirectorIdChecked(false);
        }
      } else {
        setDirectorIdCheckMessage(result.message);
        setIsDirectorIdChecked(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setDirectorIdCheckMessage("아이디 중복 검사 중 에러 발생.");
      setIsDirectorIdChecked(false);
    } finally {
      setIsCheckingDirectorId(false);
    }
  };

  const checkDirectorEmailDuplicate = async (email) => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_HOST
        }/api/users/check-dupl?email=${encodeURIComponent(email)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();

      if (result.code === "200") {
        if (result.data.isOk) {
          setDirectorEmailCheckMessage("사용 가능한 이메일입니다.");
          setIsDirectorEmailChecked(true);
        } else {
          setDirectorEmailCheckMessage("이미 사용 중인 이메일입니다.");
          setIsDirectorEmailChecked(false);
        }
      } else {
        setDirectorEmailCheckMessage(result.message);
        setIsDirectorEmailChecked(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setDirectorEmailCheckMessage("이메일 중복 검사 중 에러 발생.");
      setIsDirectorEmailChecked(false);
    } finally {
      setIsCheckingDirectorEmail(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !window.confirm(
        "이메일은 한 번 등록하면 변경할 수 없습니다. 정말로 가입하시겠습니까?"
      )
    ) {
      return;
    }

    // 학원 정보 유효성 검사
    if (!isNameChecked) {
      alert("학원 이름 중복 검사를 완료해주세요.");
      return;
    }

    if (!validateAcademyEmail(academy.email).isValid) {
      alert("학원 이메일을 확인해주세요.");
      return;
    }

    // 원장 정보 유효성 검사
    const requiredFields = [
      { value: director.loginId, message: "로그인 아이디를 입력해주세요." },
      { value: director.loginPw, message: "비밀번호를 입력해주세요." },
      { value: director.name, message: "이름을 입력해주세요." },
      { value: director.email, message: "이메일을 입력해주세요." },
    ];

    for (const field of requiredFields) {
      if (!field.value) {
        alert(field.message);
        return;
      }
    }

    if (!validateDirectorLoginId(director.loginId).isValid) {
      alert("로그인 아이디를 확인해주세요.");
      return;
    }

    if (!validateDirectorPassword(director.loginPw).isValid) {
      alert("비밀번호를 확인해주세요.");
      return;
    }

    if (!validateDirectorPassword(passwordConfirm).isValid) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    if (!validateDirectorName(director.name).isValid) {
      alert("학원장 이름을 확인해주세요.");
      return;
    }

    if (!validateDirectorEmail(director.email).isValid) {
      alert("학원장 이메일을 확인해주세요.");
      return;
    }

    // 원장 정보 중복 검사
    if (!isDirectorIdChecked) {
      alert("원장 로그인 아이디 중복 검사를 완료해주세요.");
      return;
    }

    if (!isDirectorEmailChecked) {
      alert("원장 이메일 중복 검사를 완료해주세요.");
      return;
    }

    // director 에서 불필요한 필드 loginPwConfirm 제거
    delete director.loginPwConfirm;
    const data = { academy, director };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/academies`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const responseData = await response.json();
      if (response.ok && responseData.code === "200") {
        alert(
          "등록 성공에 성공했습니다. 원장님 이메일에서 본인인증을 완료해주세요."
        );
      } else {
        alert("등록 실패.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("등록 중 에러 발생.");
    }
  };

  return (
    <Box maxWidth="1245px" mx="auto">
      <form onSubmit={handleSubmit} className="space-y-4">
        <h1 className="headline-m font-bold mt-6 mb-10">회원가입</h1>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">학원명</label>
          </Box>

          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="name"
              value={academy.name}
              onChange={(e) => handleInputChange(e, "academy")}
              className="block w-full p-2 border border-gray-300 rounded"
              placeholder="학원명을 입력해주세요."
              required
            />
            {isCheckingName ? (
              <p className="mt-1 text-blue-500">
                <Spinner />
              </p>
            ) : (
              <p
                className={`mt-1 ${
                  isNameChecked ? "text-green-500" : "text-red-500"
                }`}
              >
                {nameCheckMessage}
              </p>
            )}
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">이메일</label>
          </Box>
          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="email"
              name="email"
              value={academy.email}
              onChange={(e) => handleInputChange(e, "academy")}
              className="block w-full p-2 border border-gray-300 rounded"
              placeholder="이메일을 입력해주세요."
              required
            />
            {isCheckingEmail ? (
              <p className="mt-2 text-blue-500">
                <Spinner />
              </p>
            ) : (
              <p
                className={`mt-2 ${
                  isEmailChecked ? "text-green-500" : "text-red-500"
                }`}
              >
                {emailCheckMessage}
              </p>
            )}
          </Box>
        </Flex>

        <Separator orientation="horizontal" size="4" />

        <h2 className="md:text-5xl text-2xl font-bold my-10">
          원장님 가입 정보
        </h2>

        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">아이디</label>
          </Box>
          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="loginId"
              value={director.loginId}
              onChange={(e) => handleInputChange(e, "director")}
              className="block w-full p-2 border border-gray-300 rounded"
              placeholder="아이디를 입력해주세요."
              required
            />
            {isCheckingDirectorId ? (
              <p className="mt-1 text-blue-500">
                <Spinner />
              </p>
            ) : (
              <p
                className={`mt-1 ${
                  isDirectorIdChecked ? "text-green-500" : "text-red-500"
                }`}
              >
                {directorIdCheckMessage}
              </p>
            )}
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">비밀번호</label>
          </Box>
          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="password"
              name="loginPw"
              value={director.loginPw}
              onChange={(e) => handleInputChange(e, "director")}
              className="block w-full p-2 border border-gray-300 rounded"
              placeholder="비밀번호를 입력해주세요."
              required
            />
            <p
              className={`mt-1 ${
                directorValidation.loginPw ? "text-red-500" : "text-green-500"
              }`}
            >
              {directorValidation.loginPw}
            </p>
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">비밀번호 확인</label>
          </Box>
          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="password"
              name="loginPwConfirm"
              value={passwordConfirm}
              onChange={(e) => handleInputChange(e, "director")}
              className="block w-full p-2 border border-gray-300 rounded"
              placeholder="비밀번호를 한번 더 입력해주세요."
              required
            />
            <p
              className={`mt-1 ${
                isPasswordConfirmed ? "text-green-500" : "text-red-500"
              }`}
            >
              {passwordConfirmMessage}
            </p>
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">이름</label>
          </Box>
          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="text"
              name="name"
              value={director.name}
              onChange={(e) => handleInputChange(e, "director")}
              className="block w-full p-2 border border-gray-300 rounded"
              placeholder="원장님 이름을 입력해주세요."
              required
            />
            <p
              className={`mt-1 ${
                directorValidation.name ? "text-red-500" : "text-green-500"
              }`}
            >
              {directorValidation.name}
            </p>
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">이메일</label>
          </Box>
          <Box width="360px">
            <input
              autoComplete="off"
              onPaste={(e) => e.preventDefault()} // Prevent paste
              type="email"
              name="email"
              value={director.email}
              onChange={(e) => handleInputChange(e, "director")}
              className="block w-full p-2 border border-gray-300 rounded"
              placeholder="필수 입력 항목"
              required
            />
            {isCheckingDirectorEmail ? (
              <p className="mt-1 text-blue-500">
                <Spinner />
              </p>
            ) : (
              <p
                className={`mt-1 ${
                  isDirectorEmailChecked ? "text-green-500" : "text-red-500"
                }`}
              >
                {directorEmailCheckMessage}
              </p>
            )}
          </Box>
        </Flex>
        <Flex align="center">
          <Box width="100px" mt="2">
            <label className="block mb-2">공개여부</label>
          </Box>
          <Box>
            <Text as="label">
              <Switch variant="soft" size="3" />
              <span className="ml-2 text-neutral-50 font-medium cursor-pointer">
                참여학원에서 이 학원을 검색하도록 혀용합니다.(선택)
              </span>
            </Text>
          </Box>
        </Flex>

        <Flex direction="column" width="80px" pt="25px">
          <Button
            type="submit"
            color="blue"
            size="3"
            className="hover:bg-secondary transition-colors"
          >
            등록
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default SignUp;

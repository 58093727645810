import React, { useState, useEffect } from "react";
import { StudyClassification } from "../constants/Constants";
import { toast } from "react-toastify";

const StudyWordSelect = ({
  word,
  ignoreCase,
  onSubmit,
  nextSlide,
  slideVisible,
  studyClassification,
}) => {
  const [selectedChoice, setSelectedChoice] = useState("");
  const isPractice = studyClassification === StudyClassification.PRACTICE.name;

  const checkAnswer = (e) => {
    const correct = ignoreCase
      ? word.answer?.toLowerCase() === selectedChoice.toLowerCase()
      : word.answer === selectedChoice;

    if (correct) {
      toast.success("훌륭합니다!", {
        position: "top-center",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      onSubmit(word.id, selectedChoice, true, 300);
      setTimeout(() => {
        nextSlide();
      }, 500);
    } else {
      toast.error("다시 한 번 고민해보세요", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    if (slideVisible && selectedChoice) {
      document.querySelector('input[name="choice"]:checked')?.focus();
    }
  }, [slideVisible]);

  const handleSelect = (e) => {
    setSelectedChoice(e.target.value);
    if (isPractice) {
      checkAnswer(e);
      return;
    }
    toast.success("제출되었습니다!", {
      position: "top-center",
      autoClose: 300,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
    onSubmit(word.id, selectedChoice, true, 300);
    setTimeout(() => {
      nextSlide();
    }, 300);
  };

  function handleChange(e) {
    setSelectedChoice(e.target.value);
    onSubmit(word.id, e.target.value, false);
  }

  return (
    <div className="study-word-select flex flex-col items-center">
      <div className="text-lg text-neutral-90">{word.question}</div>
      <div className="flex max-md:flex-col w-full mt-4 md:gap-10">
        {word.choices.map((choice, index) => (
          <div key={index}>
            <label className="flex items-center max-md:justify-center gap-1 cursor-pointer">
              <input
                type="radio"
                name="choice"
                value={choice}
                // checked={selectedChoice === choice}
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && selectedChoice) {
                    handleSelect(e);
                  }
                }}
                onClick={(e) => {
                  setSelectedChoice(e.target.value);
                  // chekck 를 true 로 변경
                  e.target.checked = true;
                }}
              />
              {choice}
            </label>
          </div>
        ))}
      </div>
      <button
        className={`${
          selectedChoice ? "bg-primary" : "bg-gray-300"
        } text-white py-2 px-4 rounded mt-4`}
        onClick={(e) => handleSelect(e)}
        disabled={!selectedChoice}
      >
        선택하기
      </button>
    </div>
  );
};

export default StudyWordSelect;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Flex, TextField } from "@radix-ui/themes";

const EndTrial = () => {
  const navigate = useNavigate();
  const [loginId, setLoginId] = useState("");
  const [loginPw, setLoginPw] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  const handleEndTrial = async (e) => {
    e.preventDefault();

    if (!loginId) {
      alert("아이디를 입력해주세요.");
      return;
    }

    if (!loginPw) {
      alert("비밀번호를 입력해주세요.");
      return;
    }

    setLoading(true); // Disable button

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/academies/end-trial`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ loginId, loginPw }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        if (data && data.code === "200" && data.data.endTrial) {
          alert("정규회원으로 전환되었습니다.");
          navigate("/sign-in");
        } else if (data && data.data) {
          alert(data.data);
        } else {
          alert("정규회원 전환에 실패했습니다.");
        }
      } else {
        alert("정규회원 전환에 실패했습니다. 관리자에게 문의해주세요.");
        console.error("Failed to convert to full member");
      }
    } catch (error) {
      console.error("Failed to convert to full member", error);
      alert("정규회원 전환 중 오류가 발생했습니다. 다시 시도해주세요.");
    } finally {
      setLoading(false); // Re-enable button
    }
  };

  return (
    <div className="flex h-fill items-center justify-center bg-gray-100">
      <div className="md:w-[575px] max-w-[90%] p-8 bg-white rounded-xl shadow-lg">
        <h2 className="md:text-5xl text-2xl font-bold mb-4">
          정규회원으로 전환하기
        </h2>
        <form onSubmit={handleEndTrial} className="space-y-6">
          <div>
            <label
              htmlFor="loginId"
              className="block mb-1 font-bold text-neutral-50"
            >
              아이디
            </label>
            <Box>
              <TextField.Root
                size="3"
                radius="large"
                variant="surface"
                placeholder="아이디를 입력해주세요"
                autoComplete="off"
                onPaste={(e) => e.preventDefault()} // Prevent paste
                type="text"
                id="loginId"
                value={loginId}
                onChange={(e) => setLoginId(e.target.value)}
                required
                disabled={loading} // Disable input if loading
              />
            </Box>
          </div>
          <div>
            <label
              htmlFor="loginPw"
              className="block mb-1 font-bold text-neutral-50"
            >
              비밀번호
            </label>
            <Box>
              <TextField.Root
                size="3"
                radius="large"
                variant="surface"
                placeholder="비밀번호를 입력해주세요"
                autoComplete="off"
                onPaste={(e) => e.preventDefault()} // Prevent paste
                type="password"
                id="loginPw"
                value={loginPw}
                onChange={(e) => setLoginPw(e.target.value)}
                required
                disabled={loading} // Disable input if loading
              />
            </Box>
            {errorMessage && <p className="text-warn mt-1">{errorMessage}</p>}
          </div>

          <Flex direction="column" align="stretch">
            <Button
              type="submit"
              color="blue"
              size="3"
              className={`hover:bg-secondary transition-colors${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
            >
              {loading ? "전환 중..." : "정규회원으로 전환"}
            </Button>
          </Flex>
        </form>

        <p className="text-warn mt-4">*정규회원 전환은 원장님만 가능합니다.</p>

        <div className="flex flex-col gap-3 max-md:hidden mt-40">
          <Flex gap="2">
            <span className="text-neutral-50">
              다시 로그인하러 가시겠습니까?
            </span>
            <Link to="/sign-in" className="text-primary hover:underline">
              로그인 페이지로
            </Link>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default EndTrial;

import React, { useState, useEffect } from "react";
import UseFetchToken from "../hooks/UseFetchToken";
import { RoleName, UserStatus } from "../constants/Constants";
import PasswordChange from "./PasswordChange";
import { Box, Button, Flex, Spinner } from "@radix-ui/themes";
import { Cross1Icon } from "@radix-ui/react-icons";
import * as Dialog from "@radix-ui/react-dialog";

const MyPageStudent = () => {
  const [studentData, setStudentData] = useState({
    loginId: "",
    email: "",
    name: "",
    phone: "",
    street: "",
    addressDetail: "",
    postalCode: "",
    status: "",
    roles: [],
    createdDateTime: "",
    attendanceId: "",
    schoolName: "",
    schoolGrade: "",
    parentName: "",
    parentPhone: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the student data
    const fetchStudent = async () => {
      setLoading(true);
      try {
        const data = await UseFetchToken("/api/users/me", {
          method: "GET",
        });
        setStudentData(data);
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
      setLoading(false);
    };
    fetchStudent();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }

  const getStatusText = (status) => {
    const statusEntry = UserStatus[status];
    return statusEntry ? statusEntry.value : "알 수 없음";
  };

  const getRoleText = (roles) => {
    if (roles.includes("ROLE_DIRECTOR")) {
      return RoleName.ROLE_DIRECTOR.value;
    } else if (roles.includes("ROLE_TEACHER")) {
      return RoleName.ROLE_TEACHER.value;
    } else if (roles.includes("ROLE_STUDENT")) {
      return RoleName.ROLE_STUDENT.value;
    } else {
      return "";
    }
  };

  return (
    <Box maxWidth="1245px" mx="auto">
      <form className="space-y-4 p-6">
        <h2 className="md:text-5xl text-2xl font-bold mt-6 mb-10">
          마이페이지
        </h2>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">아이디</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded-md bg-gray-100">
              {studentData.loginId}
            </p>
          </Box>
        </Flex>
        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">출석 아이디</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded-md bg-gray-100">
              {studentData.attendanceId}
            </p>
          </Box>
        </Flex>

        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">이름</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded">
              {studentData.name}
            </p>
          </Box>
        </Flex>

        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">연락처</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded">
              {studentData.phone}
            </p>
          </Box>
        </Flex>

        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">주소</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded">
              {studentData.street}
            </p>
          </Box>
        </Flex>

        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">상세주소</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded">
              {studentData.addressDetail}
            </p>
          </Box>
        </Flex>

        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">우편번호</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded">
              {studentData.postalCode}
            </p>
          </Box>
        </Flex>

        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">학교</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded">
              {studentData.schoolName}
              {studentData.schoolGrade
                ? `(${studentData.schoolGrade}학년)`
                : ""}
            </p>
          </Box>
        </Flex>

        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">보호자</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded">
              {studentData.parentName}
              {studentData.parentPhone ? `(${studentData.parentPhone})` : ""}
            </p>
          </Box>
        </Flex>

        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">회원 상태</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded">
              {getStatusText(studentData.status)}
            </p>
          </Box>
        </Flex>

        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">권한</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded">
              {getRoleText(studentData.roles)}
            </p>
          </Box>
        </Flex>

        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">가입일</label>
          </Box>

          <Box width="360px">
            <p className="block w-full p-2 border border-gray-300 rounded">
              {(() => {
                const date = new Date(studentData.createdDateTime);
                const year = date.getFullYear();
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const day = ("0" + date.getDate()).slice(-2);
                return `${year}년 ${month}월 ${day}일`;
              })()}
            </p>
          </Box>
        </Flex>

        <Flex align="base">
          <Box width="100px" mt="2">
            <label className="block mb-2">비밀번호 변경</label>
          </Box>

          <Box width="360px">
            <Dialog.Root>
              <Dialog.Trigger asChild>
                <Button
                  type="button"
                  color="blue"
                  size="3"
                  className="hover:bg-secondary transition-colors"
                >
                  변경
                </Button>
              </Dialog.Trigger>
              <Dialog.Portal>
                <Dialog.Overlay className="DialogOverlay" />
                <Dialog.Content className="DialogContent">
                  <Dialog.Title className="headline-m">
                    <b>비밀번호 변경</b>
                  </Dialog.Title>
                  <PasswordChange />
                  <Dialog.Close asChild>
                    <button className="IconButton" aria-label="Close">
                      <Cross1Icon />
                    </button>
                  </Dialog.Close>
                </Dialog.Content>
              </Dialog.Portal>
            </Dialog.Root>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};

export default MyPageStudent;

// src/pages/NotFound.js
import React from "react";
import { Link } from "react-router-dom";
import { ExclamationTriangleIcon, ArrowLeftIcon } from "@radix-ui/react-icons";

export default function NotFound() {
  return (
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex w-max flex-col items-center justify-center">
      <ExclamationTriangleIcon width="64" height="64" color="orange" />
      <h1 className="display-s mt-2 text-center">
        존재하지 않는 페이지 입니다
      </h1>
      <Link
        to="/"
        className="flex items-center gap-2 mt-10 bg-primary text-white rounded-full px-5 py-2"
      >
        <ArrowLeftIcon />
        메인으로 돌아가기
      </Link>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import UseFetchToken from "../hooks/UseFetchToken";
import {
  StudyClassification,
  StudyStatus,
  StudyTarget,
  StudyType,
} from "../constants/Constants";
import { toast, ToastContainer } from "react-toastify";
import { Box, Button } from "@radix-ui/themes";
import "react-toastify/dist/ReactToastify.css";
import { MagnifyingGlassIcon, CalendarIcon } from "@radix-ui/react-icons";

const Studies = () => {
  const navigate = useNavigate();
  const [studies, setStudies] = useState([]);
  const [lastSequence, setLastSequence] = useState(null);
  const [hasNext, setHasNext] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [inputParams, setInputParams] = useState({
    startDateTime: "",
    endDateTime: "",
    studyTitle: "",
    studentName: "",
    schoolGrade: "",
    studyStatus: "",
  });
  const loaderRef = useRef(null);
  const isTeacher = JSON.parse(localStorage.getItem("roles")).includes(
    "ROLE_TEACHER"
  );

  const fetchStudies = async (lastSeq, params = {}) => {
    setLoading(true);
    if (!lastSeq) {
      lastSeq = "";
    }

    const queryParams = new URLSearchParams({
      size: 20,
      lastSequence: lastSeq,
      ...params,
      startDateTime: params.startDateTime
        ? `${params.startDateTime}T00:00:00`
        : "",
      endDateTime: params.endDateTime ? `${params.endDateTime}T23:59:59` : "",
    });

    try {
      const data = await UseFetchToken(
        `/api/studies?${queryParams.toString()}`,
        {
          method: "GET",
        }
      );

      if (data) {
        setStudies((prevStudies) => [...prevStudies, ...data.studies]);
        setLastSequence(data.slicingInfo.lastSequence);
        setHasNext(data.slicingInfo.hasNext);

        if (!data.slicingInfo.hasNext) {
          toast.success("마지막 항목까지 모두 조회했습니다.", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setPageLoading(false);
    setLoading(false);
  };

  useEffect(() => {
    fetchStudies(null, inputParams);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNext && !loading) {
          fetchStudies(lastSequence, inputParams);
        }
      },
      { threshold: 0.1 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [lastSequence, hasNext, loading, inputParams]);

  if (pageLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setStudies([]);
    setLastSequence(null);
    fetchStudies(null, inputParams);
  };

  const handleStudyClick = (id, status) => {
    if (status === StudyStatus.SUBMITTED.name || isTeacher) {
      navigate(`/studies/${id}/result`);
    } else {
      navigate(`/studies/${id}/start`);
    }
  };

  const renderSchoolGradeOptions = () => {
    const grades = [
      { label: "초등1", value: 1 },
      { label: "초등2", value: 2 },
      { label: "초등3", value: 3 },
      { label: "초등4", value: 4 },
      { label: "초등5", value: 5 },
      { label: "초등6", value: 6 },
      { label: "중등1", value: 7 },
      { label: "중등2", value: 8 },
      { label: "중등3", value: 9 },
      { label: "고등1", value: 10 },
      { label: "고등2", value: 11 },
      { label: "고등3", value: 12 },
    ];
    return grades.map((grade) => (
      <option key={grade.value} value={grade.value}>
        {grade.label}
      </option>
    ));
  };

  const findSchoolGradeLables = (schoolGrade) => {
    const grades = [
      { label: "1학년", value: 1 },
      { label: "2학년", value: 2 },
      { label: "3학년", value: 3 },
      { label: "4학년", value: 4 },
      { label: "5학년", value: 5 },
      { label: "6학년", value: 6 },
      { label: "1학년", value: 7 },
      { label: "2학년", value: 8 },
      { label: "3학년", value: 9 },
      { label: "1학년", value: 10 },
      { label: "2학년", value: 11 },
      { label: "3학년", value: 12 },
    ];
    return grades
      .filter((grade) => grade.value === schoolGrade)
      .map((grade) => grade.label);
  };

  const renderStatusOptions = () => {
    return StudyStatus.values()
      .filter((s) => s.name !== StudyStatus.DELETED.name)
      .map((status) => (
        <option key={status.name} value={status.name}>
          {status.value}
        </option>
      ));
  };

  const findStudyStatusValue = (studyStatus) => {
    return StudyStatus.values()
      .filter((s) => s.name === studyStatus)
      .map((status) => (
        <span
          key={status.name}
          value={status.name}
          className={
            status.name === StudyStatus.SUBMITTED.name
              ? ""
              : "font-bold text-sky-500"
          }
        >
          {status.value}
        </span>
      ));
  };

  return (
    <Box maxWidth="1245px" mx="auto" mb="9" className="max-md:p-5">
      <ToastContainer />
      <h1 className="headline-m font-bold mt-10 mb-16">학습 목록</h1>

      <form onSubmit={handleSearchSubmit} className="mb-4 space-y-4">
        <div className="grid md:grid-cols-2 gap-4 bg-neutral-5 p-4 rounded-lg">
          <div className="flex max-md:flex-col items-center md:divide-x max-md:divide-y bg-white border rounded">
            <select
              name="studyStatus"
              value={inputParams.studyStatus}
              onChange={handleInputChange}
              className="px-2 w-36 max-md:w-full max-md:p-2"
            >
              <option value="">전체</option>
              {renderStatusOptions()}
            </select>
            <div className="flex gap-1 items-center px-2 flex-1 max-md:w-full max-md:p-2">
              <MagnifyingGlassIcon color="#3AACF5" />
              <input
                autoComplete="off"
                type="text"
                name="studyTitle"
                value={inputParams.studyTitle}
                onChange={handleInputChange}
                placeholder="학습 제목을 입력해주세요"
              />
            </div>
          </div>

          <div>
            <Button
              type="submit"
              color="blue"
              size="3"
              className="hover:bg-secondary transition-colors max-md:w-full"
            >
              검색
            </Button>
          </div>

          {isTeacher && (
            <>
              <div className="flex items-center divide-x h-10 bg-white border rounded">
                <select
                  name="schoolGrade"
                  value={inputParams.schoolGrade}
                  onChange={handleInputChange}
                  className="px-2 w-36"
                >
                  <option value="">학년 선택</option>
                  {renderSchoolGradeOptions()}
                </select>

                <div className="flex gap-1 items-center px-2 flex-1">
                  <MagnifyingGlassIcon color="#3AACF5" />
                  <input
                    autoComplete="off"
                    type="text"
                    name="studentName"
                    value={inputParams.studentName}
                    onChange={handleInputChange}
                    placeholder="학생 이름"
                  />
                </div>
              </div>

              <div></div>
            </>
          )}

          <div className="flex gap-4">
            <div className="flex justify-center items-center px-3 bg-white shadow-md rounded-lg">
              <CalendarIcon width="21" height="21" />
            </div>
            <input
              type="date"
              name="startDateTime"
              value={inputParams.startDateTime}
              onChange={handleInputChange}
              className="p-2 border rounded w-full"
            />
            <input
              type="date"
              name="endDateTime"
              value={inputParams.endDateTime}
              onChange={handleInputChange}
              className="p-2 border rounded w-full"
            />
          </div>
        </div>
      </form>

      {studies.length === 0 && !loading && (
        <div className="text-center py-4">조회 결과가 없습니다.</div>
      )}

      {studies.length > 0 && (
        <ul className="space-y-4">
          {studies.map((study) => (
            <li
              key={study.id}
              onClick={() => handleStudyClick(study.id, study.status)}
              className="p-4 border rounded-lg cursor-pointer hover:bg-neutral-5"
            >
              <h2 className="headline-s font-bold mb-2">{study.title}</h2>
              {isTeacher && (
                <p className="text-sm">
                  <b>학생 정보</b> {study.studentName}({study.schoolName}{" "}
                  {findSchoolGradeLables(study.schoolGrade)})
                </p>
              )}
              <p className="text-sm">
                <b>학습 정보</b> {StudyTarget[study.target].value}{" "}
                {StudyType[study.type].value}{" "}
                {StudyClassification[study.classification].value}
              </p>
              <p className="text-sm">
                <b>학습 상태</b> {findStudyStatusValue(study.status)}{" "}
                {study.status === StudyStatus.SUBMITTED.name && (
                  <span>
                    <span
                      className={`
                        ${
                          study.correctCount === study.totalCount
                            ? "font-extrabold text-sky-500"
                            : study.correctCount / study.totalCount < 0.4
                            ? "font-extrabold text-warn"
                            : ""
                        }`}
                    >
                      {study.correctCount}
                    </span>
                    /{study.totalCount}
                  </span>
                )}
              </p>

              <p className="text-sm">
                <b>학습 생성일 </b>
                {(() => {
                  const date = new Date(study.createdDateTime);
                  const year = date.getFullYear();
                  const month = ("0" + (date.getMonth() + 1)).slice(-2);
                  const day = ("0" + date.getDate()).slice(-2);
                  return `${year}년 ${month}월 ${day}일`;
                })()}
              </p>
            </li>
          ))}
        </ul>
      )}

      {hasNext && studies.length > 0 && (
        <div ref={loaderRef} className="text-center py-4">
          {loading ? "교재를 검색 중입니다..." : ""}
        </div>
      )}

      {!hasNext && studies.length > 0 && (
        <div className="text-center py-4">
          마지막 항목까지 모두 조회했습니다.
        </div>
      )}
    </Box>
  );
};

export default Studies;

import React, { useState, useEffect } from "react";
import { UserStatus } from "../constants/Constants";
import { useNavigate, Link } from "react-router-dom";
import UseFetchToken from "../hooks/UseFetchToken";
import {
  Box,
  Badge,
  Button,
  Select,
} from "@radix-ui/themes";
import {
  MagnifyingGlassIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  TextAlignCenterIcon,
} from "@radix-ui/react-icons";

const Students = () => {
  const navigate = useNavigate();

  const [students, setStudents] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDetailedSearch, setShowDetailedSearch] = useState(false);
  const [searchParams, setSearchParams] = useState({
    page: 1,
    size: 10,
    name: "",
    address: "",
    attendanceId: "",
    schoolName: "",
    parentName: "",
    status: "",
    schoolGrade: "",
    prop: "name",
    dir: "asc",
  });
  const [inputParams, setInputParams] = useState({
    name: "",
    address: "",
    attendanceId: "",
    schoolName: "",
    parentName: "",
    status: "",
    schoolGrade: "",
    prop: "",
    dir: "",
  });

  const fetchStudents = async (params) => {
    setLoading(true);
    setError(null);

    const queryString = new URLSearchParams(params).toString();

    try {
      const data = await UseFetchToken(`/api/students?${queryString}`, {
        method: "GET",
      });

      if (data) {
        setStudents(data.students);
        setPageInfo(data.pageInfo);
      }
    } catch (error) {
      console.error("Error:", error);
      setError("학생 목록을 불러오는 중 오류가 발생했습니다.");
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchStudents(searchParams);
  }, [searchParams]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen text-red-500">
        {error}
      </div>
    );
  }

  const handleInputChange = (eOrName, value) => {
    if (typeof eOrName === "string") {
      setInputParams((prevParams) => ({
        ...prevParams,
        [eOrName]: value,
      }));
    } else {
      const { name, value } = eOrName.target;
      setInputParams((prevParams) => ({
        ...prevParams,
        [name]: value,
      }));
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchParams((prevParams) => ({
      ...prevParams,
      ...inputParams,
      page: 1,
    }));
    if (showDetailedSearch) {
      setShowDetailedSearch(true);
    }
  };

  const handlePageChange = (newPage) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      page: newPage,
    }));
  };

  const handlePageSetChange = (direction) => {
    const startPage = Math.floor((pageInfo.page - 1) / 10) * 10 + 1;
    const newPage =
      direction === "first"
        ? 1
        : direction === "last"
        ? pageInfo.lastPage
        : direction === "prev"
        ? Math.max(1, startPage - 10)
        : Math.min(pageInfo.lastPage, startPage + 10);

    setSearchParams((prevParams) => ({
      ...prevParams,
      page: newPage,
    }));
  };

  const renderPagination = () => {
    const startPage = Math.floor((pageInfo.page - 1) / 10) * 10 + 1;
    const endPage = Math.min(startPage + 9, pageInfo.lastPage);
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    const isFirstSet = pageInfo.page <= 10;
    const isLastSet = endPage === pageInfo.lastPage;

    return (
      <div className="flex justify-center mt-14">
        <button
          onClick={() => handlePageSetChange("first")}
          disabled={isFirstSet}
          className={`p-4 bg-white text-neutral-80 rounded-lg ${
            isFirstSet && "opacity-50 cursor-not-allowed"
          }`}
        >
          <DoubleArrowLeftIcon />
        </button>
        <button
          onClick={() => handlePageSetChange("prev")}
          disabled={isFirstSet}
          className={`p-4 bg-white text-neutral-80 rounded-lg mr-2 ${
            isFirstSet && "opacity-50 cursor-not-allowed"
          }`}
        >
          <ChevronLeftIcon />
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={`px-5 ${
              number === pageInfo.page
                ? "text-neutral-100 bg-neutral-5"
                : "text-neutral-80"
            } rounded-lg`}
          >
            {number}
          </button>
        ))}
        <button
          onClick={() => handlePageSetChange("next")}
          disabled={isLastSet}
          className={`p-4 bg-white text-neutral-80 rounded-lg ml-2 ${
            isLastSet && "opacity-50 cursor-not-allowed"
          }`}
        >
          <ChevronRightIcon />
        </button>
        <button
          onClick={() => handlePageSetChange("last")}
          disabled={isLastSet}
          className={`p-4 bg-white text-neutral-80 rounded-lg ${
            isLastSet && "opacity-50 cursor-not-allowed"
          }`}
        >
          <DoubleArrowRightIcon />
        </button>
      </div>
    );
  };

  const renderSchoolGradeOptions = () => {
    const grades = [
      { label: "초등1", value: 1 },
      { label: "초등2", value: 2 },
      { label: "초등3", value: 3 },
      { label: "초등4", value: 4 },
      { label: "초등5", value: 5 },
      { label: "초등6", value: 6 },
      { label: "중등1", value: 7 },
      { label: "중등2", value: 8 },
      { label: "중등3", value: 9 },
      { label: "고등1", value: 10 },
      { label: "고등2", value: 11 },
      { label: "고등3", value: 12 },
    ];
    return grades.map((grade) => (
      <option key={grade.value} value={grade.value}>
        {grade.label}
      </option>
    ));
  };

  const renderStatusOptions = () => {
    return UserStatus.values().map((status) => (
      <option key={status.name} value={status.name}>
        {status.value}
      </option>
    ));
  };

  const formatSchoolGrade = (grade) => {
    if (grade >= 1 && grade <= 6) {
      return `초등${grade}`;
    } else if (grade >= 7 && grade <= 9) {
      return `중등${grade - 6}`;
    } else if (grade >= 10 && grade <= 12) {
      return `고등${grade - 9}`;
    }
    return "";
  };

  return (
    <Box maxWidth="1245px" mx="auto" mb="9">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2 mt-10 mb-16">
          <Badge size="2" color="orange" className="px-4 text-base">
            학원생
          </Badge>
          <h1 className="headline-m font-bold">학생목록</h1>
        </div>
        <Link to="/students/new">
          <Button size="2" color="blue" className="px-4">
            새 등록
          </Button>
        </Link>
      </div>

      <div className="w-full mx-auto">
        <div className="mb-4 bg-neutral-5 p-5 rounded-lg">
          <form
            onSubmit={handleSearchSubmit}
            className="w-full flex items-center space-x-2"
          >
            <Select.Root
              size="3"
              name="prop"
              value={inputParams.prop}
              onValueChange={(v) => handleInputChange("prop", v)}
            >
              <Select.Trigger placeholder="정렬 속성" />
              <Select.Content>
                <Select.Item value="name">이름</Select.Item>
                <Select.Item value="status">상태</Select.Item>
                <Select.Item value="schoolName">학교 이름</Select.Item>
                <Select.Item value="attendanceId">출석 아이디</Select.Item>
              </Select.Content>
            </Select.Root>

            <Select.Root
              size="3"
              name="dir"
              value={inputParams.dir}
              onValueChange={(v) => handleInputChange("dir", v)}
            >
              <Select.Trigger placeholder="정렬 방향" />
              <Select.Content>
                <Select.Item value="asc">오름차순</Select.Item>
                <Select.Item value="desc">내림차순</Select.Item>
              </Select.Content>
            </Select.Root>

            <div className="w-128 flex gap-1 items-center p-2 bg-white border rounded">
              <MagnifyingGlassIcon width="20" height="24" color="#3AACF5" />
              <input
                className="flex-1"
                autoComplete="off"
                onPaste={(e) => e.preventDefault()} // Prevent paste
                type="text"
                name="name"
                value={inputParams.name}
                onChange={handleInputChange}
                placeholder="학생 이름을 입력해주세요."
              />
            </div>

            <Button
              type="submit"
              size="3"
              color="blue"
              className="px-5 rounded"
            >
              검색
            </Button>

            <Button
              size="3"
              type="button"
              onClick={() => setShowDetailedSearch((prev) => !prev)}
              className="p-2 rounded bg-[#E5E5E5] px-2"
            >
              <TextAlignCenterIcon color="#909090" width="22" height="22" />
            </Button>
          </form>
          {showDetailedSearch && (
            <form onSubmit={handleSearchSubmit} className="my-4 space-y-4">
              <div className="w-fit flex items-center gap-2 rounded-lg bg-white px-4 py-2">
                <TextAlignCenterIcon color="#909090" width="22" height="22" />{" "}
                상세검색
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <input
                    autoComplete="off"
                    onPaste={(e) => e.preventDefault()} // Prevent paste
                    type="text"
                    name="address"
                    value={inputParams.address}
                    onChange={handleInputChange}
                    placeholder="주소"
                    className="p-2 border border-neutral-10 rounded w-full"
                  />
                </div>
                <div>
                  <input
                    autoComplete="off"
                    onPaste={(e) => e.preventDefault()} // Prevent paste
                    type="text"
                    name="attendanceId"
                    value={inputParams.attendanceId}
                    onChange={handleInputChange}
                    placeholder="출석 아이디"
                    className="p-2 border border-neutral-10 rounded w-full"
                  />
                </div>
                <div>
                  <input
                    autoComplete="off"
                    onPaste={(e) => e.preventDefault()} // Prevent paste
                    type="text"
                    name="schoolName"
                    value={inputParams.schoolName}
                    onChange={handleInputChange}
                    placeholder="학교 이름"
                    className="p-2 border border-neutral-10 rounded w-full"
                  />
                </div>
                <div>
                  <input
                    autoComplete="off"
                    onPaste={(e) => e.preventDefault()} // Prevent paste
                    type="text"
                    name="parentName"
                    value={inputParams.parentName}
                    onChange={handleInputChange}
                    placeholder="부모 이름"
                    className="p-2 border border-neutral-10 rounded w-full"
                  />
                </div>
                <div>
                  <select
                    name="status"
                    value={inputParams.status}
                    onChange={handleInputChange}
                    className="p-2 border border-neutral-10 rounded w-full"
                  >
                    <option value="">학생 상태 선택</option>
                    {renderStatusOptions()}
                  </select>
                </div>
                <div>
                  <select
                    name="schoolGrade"
                    value={inputParams.schoolGrade}
                    onChange={handleInputChange}
                    className="p-2 border border-neutral-10 rounded w-full"
                  >
                    <option value="">학년 선택</option>
                    {renderSchoolGradeOptions()}
                  </select>
                </div>
              </div>
            </form>
          )}
        </div>

        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-neutral-5 text-neutral-70">
              <th width="20"></th>
              <th className="p-3 text-left">출석번호</th>
              <th className="p-3 text-left">이름</th>
              <th className="p-3 text-left">학교</th>
              <th className="p-3 text-left">연락처</th>
              <th className="p-3 text-left">상태</th>
              <th className="p-3 text-left">가입일</th>
              <th className="p-3 text-center">개인정보</th>
              <th className="p-3 text-center">학습통계</th>
            </tr>
          </thead>
          <tbody>
            {students.length === 0 ? (
              <tr>
                <td colSpan="9" className="py-4 text-center">
                  아직 학생이 등록되지 않았습니다. 학생을 등록해주세요.
                </td>
              </tr>
            ) : (
              students.map((student) => {
                const contact = student.phone
                  ? `${student.phone}`
                  : `${student.parentPhone} (부모)`;
                const date = new Date(student.createdDateTime);
                const formattedDate = `${date.getFullYear()}년 ${(
                  "0" +
                  (date.getMonth() + 1)
                ).slice(-2)}월 ${("0" + date.getDate()).slice(-2)}일`;
                const status = UserStatus.values().find(
                  (s) => s.name === student.status
                );

                return (
                  <tr key={student.id} className="">
                    <td></td>
                    <td className="p-3">{student.attendanceId}</td>
                    <td className="p-3">{student.name}</td>
                    <td className="p-3">{`${
                      student.schoolName
                    } ${formatSchoolGrade(student.schoolGrade)}`}</td>
                    <td className="p-3">{contact}</td>
                    <td className="p-3">
                      <Badge
                        size="3"
                        color={status.value === "탈퇴" ? "orange" : "blue"}
                      >
                        {status.value}
                      </Badge>
                    </td>
                    <td className="p-3">{formattedDate}</td>
                    <td className="p-3 text-center">
                      <button
                        className="hover:underline text-neutral-80"
                        onClick={() => navigate(`/students/${student.id}`)}
                      >
                        수정
                      </button>
                    </td>
                    <td className="p-3 text-center">
                      <button
                        className="hover:underline text-neutral-80"
                        onClick={() =>
                          navigate(`/dashboards?studentId=${student.id}`)
                        }
                      >
                        보기
                      </button>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        {renderPagination()}
      </div>
    </Box>
  );
};

export default Students;

import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import { Box, Button, Flex, TextField } from "@radix-ui/themes";

const SignIn = () => {
  const navigate = useNavigate();
  const [loginId, setLoginId] = useState("");
  const [loginPw, setLoginPw] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { login } = useContext(UserContext);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!loginId || !loginPw) {
      setErrorMessage("아이디와 비밀번호를 모두 입력해주세요.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/api/users/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ loginId, loginPw }),
        }
      );

      const data = await response.json();

      if (data.code.startsWith("0")) {
        alert(data.data);
        setErrorMessage(data.data);
        return;
      }

      const {
        accessToken,
        refreshToken,
        userId,
        academyId,
        roles,
        status,
        passwordChangeRequired,
      } = data.data;

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("userId", userId);
      localStorage.setItem("academyId", academyId);
      localStorage.setItem("roles", JSON.stringify(roles));
      localStorage.setItem("status", status);

      login(roles, userId, academyId);

      if (passwordChangeRequired) {
        if (
          window.confirm(
            "비밀번호를 변경한지 3개월이 지났습니다. 지금 바로 비밀번호를 변경하시겠습니까?"
          )
        ) {
          navigate("/my-page#password-change");
        } else {
          navigate("/dashboards");
        }
      } else {
        navigate("/dashboards");
      }
    } catch (error) {
      console.error("Login failed", error);
      setErrorMessage("로그인에 실패하였습니다.");
    }
  };

  return (
    <div className="flex h-fill items-center justify-center md:bg-gray-100">
      <div className="max-md:w-full max-md:h-full md:w-[575px] md:max-w-[90%] p-8 bg-white rounded-xl md:shadow-lg">
        <h2 className="text-5xl font-bold max-md:mt-4">로그인</h2>
        <p className="mt-2 mb-16">
          썬더에듀에 오신걸 환영합니다!
          <br />
          썬더에듀를 이용하시려면 로그인해 주세요.
        </p>

        <form onSubmit={handleLogin} className="space-y-6">
          <div>
            <label
              htmlFor="loginId"
              className="block mb-1 font-bold text-neutral-50"
            >
              아이디
            </label>
            <Box>
              <TextField.Root
                size="3"
                radius="large"
                variant="surface"
                placeholder="아이디를 입력해주세요"
                autoComplete="off"
                onPaste={(e) => e.preventDefault()} // Prevent paste
                type="text"
                id="loginId"
                value={loginId}
                onChange={(e) => setLoginId(e.target.value)}
              />
            </Box>
          </div>
          <div>
            <label
              htmlFor="loginPw"
              className="block mb-1 font-bold text-neutral-50"
            >
              비밀번호
            </label>
            <Box>
              <TextField.Root
                size="3"
                radius="large"
                variant="surface"
                placeholder="비밀번호를 입력해주세요"
                autoComplete="off"
                onPaste={(e) => e.preventDefault()} // Prevent paste
                type="password"
                id="loginPw"
                value={loginPw}
                onChange={(e) => setLoginPw(e.target.value)}
              />
            </Box>
            {errorMessage && <p className="text-warn mt-1">{errorMessage}</p>}
          </div>

          <Flex direction="column" align="stretch">
            <Button
              type="submit"
              color="blue"
              size="3"
              className="hover:bg-secondary transition-colors"
            >
              로그인
            </Button>
          </Flex>
        </form>

        <div className="flex flex-col gap-3 max-md:hidden mt-20">
          <Flex gap="2">
            <span className="text-neutral-50">아직 아이디가 없으신가요?</span>
            <Link to="/sign-up" className="text-primary hover:underline">
              학원 등록하러가기
            </Link>
          </Flex>
          <Flex gap="2">
            <span className="text-neutral-50">도움이 필요하신가요?</span>
            <Link to="/help/id" className="text-primary hover:underline">
              아이디 찾기
            </Link>{" "}
            <span className="text-neutral-50">또는</span>
            <Link to="/help/pw" className="text-primary hover:underline">
              비밀번호 분실
            </Link>
          </Flex>
          <Flex gap="2">
            <span className="text-neutral-50">체험기간이 종료되었나요?</span>
            <Link to="/end-trial" className="text-primary hover:underline">
              정규회원으로 전환하기
            </Link>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default SignIn;

import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import UseFetchToken from "../hooks/UseFetchToken";

import { Box, Button, Text, Checkbox, Flex } from "@radix-ui/themes";
import {
  MagnifyingGlassIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";

const Books = () => {
  const navigate = useNavigate();
  const [books, setBooks] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({
    page: 1,
    size: 30,
    keyword: "",
    year: "",
    privateOnly: false,
  });
  const [inputParams, setInputParams] = useState({
    keyword: "",
    year: "",
    privateOnly: false,
  });

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 30 }, (_, i) => currentYear - i);

  const fetchBooks = async (params) => {
    setLoading(true);
    const queryString = new URLSearchParams(params).toString();

    try {
      const data = await UseFetchToken(`/api/books?${queryString}`, {
        method: "GET",
      });

      if (data) {
        setBooks(data.books);
        setPageInfo(data.pageInfo);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchBooks(searchParams);
  }, [searchParams]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }

  const handleInputChange = (eOrValue, name) => {
    if (typeof eOrValue === "boolean") {
      setInputParams((prevParams) => ({
        ...prevParams,
        [name]: eOrValue,
      }));
    } else {
      const { name, value, type, checked } = eOrValue.target;
      setInputParams((prevParams) => ({
        ...prevParams,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchParams((prevParams) => ({
      ...prevParams,
      ...inputParams,
      page: 1,
    }));
  };

  const handlePageChange = (newPage) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      page: newPage,
    }));
  };

  const handlePageSetChange = (direction) => {
    const startPage = Math.floor((pageInfo.page - 1) / 10) * 10 + 1;
    const newPage =
      direction === "first"
        ? 1
        : direction === "last"
        ? pageInfo.lastPage
        : direction === "prev"
        ? Math.max(1, startPage - 10)
        : Math.min(pageInfo.lastPage, startPage + 10);

    setSearchParams((prevParams) => ({
      ...prevParams,
      page: newPage,
    }));
  };

  const renderPagination = () => {
    const startPage = Math.floor((pageInfo.page - 1) / 10) * 10 + 1;
    const endPage = Math.min(startPage + 9, pageInfo.lastPage);
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    const isFirstSet = pageInfo.page <= 10;
    const isLastSet = endPage === pageInfo.lastPage;

    return (
      <div className="flex justify-center mt-14">
        <button
          onClick={() => handlePageSetChange("first")}
          disabled={isFirstSet}
          className={`p-4 bg-white text-neutral-80 rounded-lg ${
            isFirstSet && "opacity-50 cursor-not-allowed"
          }`}
        >
          <DoubleArrowLeftIcon />
        </button>
        <button
          onClick={() => handlePageSetChange("prev")}
          disabled={isFirstSet}
          className={`p-4 bg-white text-neutral-80 rounded-lg mr-2 ${
            isFirstSet && "opacity-50 cursor-not-allowed"
          }`}
        >
          <ChevronLeftIcon />
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={`px-5 ${
              number === pageInfo.page
                ? "text-neutral-100 bg-neutral-5"
                : "text-neutral-80"
            } rounded-lg`}
          >
            {number}
          </button>
        ))}
        <button
          onClick={() => handlePageSetChange("next")}
          disabled={isLastSet}
          className={`p-4 bg-white text-neutral-80 rounded-lg ml-2 ${
            isLastSet && "opacity-50 cursor-not-allowed"
          }`}
        >
          <ChevronRightIcon />
        </button>
        <button
          onClick={() => handlePageSetChange("last")}
          disabled={isLastSet}
          className={`p-4 bg-white text-neutral-80 rounded-lg ${
            isLastSet && "opacity-50 cursor-not-allowed"
          }`}
        >
          <DoubleArrowRightIcon />
        </button>
      </div>
    );
  };

  return (
    <Box maxWidth="1245px" mx="auto" mb="9">
      <div className="flex justify-between items-center">
        <h1 className="headline-m font-bold mt-10 mb-16">교재 목록</h1>
        <Link to={"/books/new"}>
          <Button size="3" color="blue" className="px-4">
            새 등록
          </Button>
        </Link>
      </div>

      <div className="flex items-center mb-3">
        <form className="flex items-center gap-3" onSubmit={handleSearchSubmit}>
          <div className="flex w-160 items-center divide-x bg-white border rounded">
            <select
              className="w-1/4 p-2"
              name="year"
              value={inputParams.year}
              onChange={handleInputChange}
            >
              <option value="">생성연도</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}년
                </option>
              ))}
            </select>
            <div className="flex gap-1 items-center px-2 flex-1">
              <MagnifyingGlassIcon color="#3AACF5" />
              <input
                type="text"
                autoComplete="off"
                className="flex-1"
                name="keyword"
                value={inputParams.keyword}
                onChange={handleInputChange}
                placeholder="키워드"
              />
            </div>
          </div>

          <Button size="3" color="blue" type="submit" className="px-6">
            검색
          </Button>

          <Text as="label" size="2">
            <Flex as="span" gap="2">
              <Checkbox
                size="2"
                color="blue"
                checked={inputParams.privateOnly}
                onCheckedChange={(checked) =>
                  handleInputChange(checked, "privateOnly")
                }
              />
              개인자료만 검색
            </Flex>
          </Text>
        </form>
      </div>

      <div className="mx-auto">
        <table className="w-full bg-white">
          <thead>
            <tr className="bg-neutral-5 text-neutral-70">
              <th width="128"></th>
              <th className="py-2 px-4 text-left">출판사</th>
              <th className="py-2 px-4 text-left">교재명</th>
              <th className="py-2 px-4 text-left">생성일</th>
              <th className="py-2 px-4 text-left">공개여부</th>
              <th></th>
              <th width="128"></th>
            </tr>
          </thead>
          <tbody>
            {books.length === 0 ? (
              <tr>
                <td colSpan={5} className="py-2 px-4 text-center">
                  아직 교재가 등록되지 않았습니다. 교재를 등록해주세요.
                </td>
              </tr>
            ) : (
              books.map((book) => {
                const createdDate = new Date(book.createdDateTime);
                const formattedDate = `${createdDate.getFullYear()}년 ${(
                  "0" +
                  (createdDate.getMonth() + 1)
                ).slice(-2)}월 ${("0" + createdDate.getDate()).slice(-2)}일`;
                const displayChapter = book.chapter ? `${book.chapter}` : "";
                const displaySubject = book.subject ? `(${book.subject})` : "";

                return (
                  <tr key={book.id}>
                    <td></td>
                    <td className="py-2 px-4">{book.publisher}</td>
                    <td className="py-2 px-4">{`${book.name} ${displayChapter}${displaySubject}`}</td>
                    <td className="py-2 px-4">{formattedDate}</td>
                    <td className="py-2 px-4">
                      {book.openToPublic ? "공개자료" : "개인자료"}
                    </td>
                    <td>
                      <span
                        className="cursor-pointer hover:underline text-neutral-80"
                        onClick={() => navigate(`/books/${book.id}`)}
                      >
                        수정
                      </span>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        {renderPagination()}
      </div>
    </Box>
  );
};

export default Books;

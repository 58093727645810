import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  StudyStatus,
  StudyType,
  StudyClassification,
  StudyTarget,
} from "../constants/Constants";
import StudyWordTracing from "../fragments/StudyWordTracing";
import StudyWordWriting from "../fragments/StudyWordWriting";
import StudyWordSelect from "../fragments/StudyWordSelect";
import UseFetchToken from "../hooks/UseFetchToken";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  Checkbox,
  Radio,
} from "@radix-ui/themes";
import {
  ReaderIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@radix-ui/react-icons";

const StudyStart = () => {
  const MAX_VISIBLE_BUTTONS = 9;
  const { studyId } = useParams();
  const navigate = useNavigate();
  const [study, setStudy] = useState(null);
  const [studyWords, setStudyWords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [submissions, setSubmissions] = useState([]);
  const [submitLastAnswer, setSubmitLastAnswer] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (Boolean(studyId)) {
      const fetchStudy = async () => {
        const data = await UseFetchToken(`/api/studies/${studyId}`, {
          method: "GET",
        });

        if (data) {
          setStudy(data.study);
          setStudyWords(data.studyWords);
        }
        setLoading(false);
      };
      fetchStudy();
    } else {
      setLoading(false);
      alert("학습 정보를 찾을 수 없습니다.");
      navigate(-1);
    }
  }, [studyId]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }

  const handleSubmission = async () => {
    if (submitted) {
      return;
    }

    // Validate submissions
    for (let i = 0; i < studyWords.length; i++) {
      const submission = submissions.find((sub) => sub.id === studyWords[i].id);
      if (
        !submission ||
        !submission.submit ||
        submission.submit.trim() === ""
      ) {
        if (
          window.confirm(
            `슬라이드 ${
              i + 1
            }번에 입력된 값이 없습니다. 해당 슬라이드로 이동하시겠습니까?`
          )
        ) {
          setCurrentSlide(i);
          setSubmitLastAnswer(false);
          return;
        }
        break;
      }
    }

    // Final confirmation if all submissions are valid
    if (window.confirm("모든 항목을 입력했습니다. 바로 제출하시겠습니까?")) {
      try {
        const payload = {
          studyWords: submissions,
        };

        const data = await UseFetchToken(`/api/studies/${study.id}/submit`, {
          method: "PATCH",
          body: JSON.stringify(payload),
        });

        if (data && data.studyId) {
          setSubmitted(true);
          toast.success("학습이 제출되었습니다!", {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });

          // 1초 후 새로고침
          setTimeout(() => {
            navigate(`/studies/${study.id}/result`); // Redirect to result page
          }, 1000);
        }
      } catch (error) {
        console.error("Error:", error);
        alert("학습 제출에 실패했습니다. 다시 시도해주세요.");
      } finally {
        setSubmitLastAnswer(false);
      }
    }
  };

  const handleAnswerSubmission = (
    studyWordId,
    answer,
    entered,
    timeout = 1000
  ) => {
    setSubmissions((prev) => {
      const updatedSubmissions = prev.filter((sub) => sub.id !== studyWordId);
      return [...updatedSubmissions, { id: studyWordId, submit: answer }];
    });
    if (currentSlide === studyWords.length - 1 && entered) {
      if (submitLastAnswer) {
        return;
      }
      setSubmitLastAnswer(true);
      setTimeout(() => {
        handleSubmission();
      }, timeout);
    }
  };

  const nextSlide = () => {
    if (currentSlide < studyWords.length - 1) setCurrentSlide(currentSlide + 1);
  };

  const prevSlide = () => {
    if (currentSlide > 0) setCurrentSlide(currentSlide - 1);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const isSubmissionEmpty = (index) => {
    const submission = submissions.find(
      (sub) => sub.id === studyWords[index].id
    );
    return !submission || !submission.submit || submission.submit.trim() === "";
  };

  const renderSlide = (word, index) => {
    const slideVisible = currentSlide === index;

    switch (study.type) {
      case StudyType.TRACING.name:
        return (
          <StudyWordTracing
            key={word.id}
            word={word}
            ignoreCase={study.ignoreCase}
            onSubmit={handleAnswerSubmission}
            nextSlide={index < studyWords.length - 1 ? nextSlide : () => {}}
            slideVisible={slideVisible}
          />
        );
      case StudyType.WRITING.name:
        return (
          <StudyWordWriting
            key={word.id}
            ignoreCase={study.ignoreCase}
            word={word}
            onSubmit={handleAnswerSubmission}
            nextSlide={index < studyWords.length - 1 ? nextSlide : () => {}}
            slideVisible={slideVisible}
            studyClassification={study.classification}
          />
        );
      case StudyType.SELECT.name:
        return (
          <StudyWordSelect
            key={word.id}
            ignoreCase={study.ignoreCase}
            word={word}
            onSubmit={handleAnswerSubmission}
            nextSlide={index < studyWords.length - 1 ? nextSlide : () => {}}
            slideVisible={slideVisible}
            studyClassification={study.classification}
          />
        );
      default:
        return null;
    }
  };

  const renderPagination = () => {
    const totalSlides = studyWords.length;
    const start = Math.max(
      0,
      Math.min(
        currentSlide - Math.floor(MAX_VISIBLE_BUTTONS / 2),
        totalSlides - MAX_VISIBLE_BUTTONS
      )
    );
    const end = Math.min(start + MAX_VISIBLE_BUTTONS, totalSlides);

    const buttons = [];
    for (let i = start; i < end; i++) {
      let buttonClass = "";
      if (currentSlide === i) {
        buttonClass = "bg-primary text-white"; // Current slide
      } else if (isSubmissionEmpty(i)) {
        buttonClass = "bg-neutral-10 text-neutral-80"; // Slide with empty submission
      } else {
        buttonClass = "bg-neutral-80 text-white"; // Slide with completed submission
      }

      buttons.push(
        <button
          key={i}
          className={`mx-1 w-8 h-8 rounded-full ${buttonClass}`}
          onClick={() => goToSlide(i)}
        >
          {i + 1}
        </button>
      );
    }

    return (
      <>
        {buttons}
        {end < totalSlides && (
          <>
            <span className="mx-1">...</span>
            <button
              className={`mx-1 w-8 h-8 rounded-full ${
                currentSlide === totalSlides - 1
                  ? "bg-primary text-white"
                  : isSubmissionEmpty(totalSlides - 1)
                  ? "bg-neutral-10 text-neutral-80"
                  : "bg-neutral-10 text-neutral-80"
              }`}
              onClick={() => goToSlide(totalSlides - 1)}
            >
              {totalSlides}
            </button>
          </>
        )}
      </>
    );
  };

  return (
    <Container className="h-fill min-h-fit flex justify-center items-center study-container p-5">
      <ToastContainer />
      <Box
        maxWidth="1245px"
        mx="auto"
        className="grid min-h-[68vh] bg-white rounded-xl p-5 overflow-auto"
      >
        <header className="md:p-4 md:bg-neutral-5 rounded-xl">
          {/* <div className="flex justify-between items-center">
            <button
              className="text-xl text-red-600 p-2"
              onClick={() => {
                if (
                  window.confirm(
                    "학습 내역이 저장되지 않습니다. 정말로 떠나시겠습니까?"
                  )
                ) {
                  navigate(-1);
                }
              }}
            >
              X
            </button>
          </div> */}
          <div className="w-fit flex max-md:flex-col items-center mx-auto md:px-14 max-md:p-2 bg-white md:shadow-md rounded-md">
            <div className="bg-neutral-5 p-1 rounded">
              <ReaderIcon width="15" height="15" />
            </div>

            <h2 className="text-lg font-bold flex justify-center items-center p-2">
              {study.title}
            </h2>
          </div>

          <div className="flex max-md:flex-col md:justify-between md:items-center p-2 md:mt-5 max-md:bg-neutral-5 rounded-lg gap-2 max-md:text-sm">
            <div className="md:w-1/4 text-center max-md:flex max-md: items-center gap-2">
              <b>학습상태</b> {StudyStatus[study.status].value}
            </div>
            <div className="md:w-1/4 text-center max-md:flex max-md: items-center gap-2">
              <b>학습유형</b> {StudyType[study.type].value}
            </div>
            <div className="md:w-1/4 text-center max-md:flex max-md: items-center gap-2">
              <b>학습분류</b> {StudyClassification[study.classification].value}
            </div>
            <div className="md:w-1/4 text-center max-md:flex max-md: items-center gap-2">
              <b>학습대상</b> {StudyTarget[study.target].value}
            </div>
          </div>
        </header>
        <div className="carousel-container relative w-full">
          <div className="carousel-inner md:h-96 min-h-48 overflow-hidden rounded-lg relative">
            {studyWords.map((word, index) => (
              <div
                key={word.id}
                className={`slide ${
                  currentSlide === index ? "block" : "hidden"
                } w-full h-full flex items-center justify-center break-words`}
              >
                {renderSlide(word, index)}
              </div>
            ))}
          </div>
          <div className="max-md:hidden carousel-controls absolute inset-y-0 left-0 right-0 flex justify-between z-10 pointer-events-none">
            <button className="ml-2 pointer-events-auto" onClick={prevSlide}>
              <ChevronLeftIcon width="36" height="36" color="#909090" />
            </button>
            <button className="mr-2 pointer-events-auto" onClick={nextSlide}>
              <ChevronRightIcon width="36" height="36" color="#909090" />
            </button>
          </div>
        </div>
        <div className="pagination-container flex justify-center mt-4 items-end">
          {renderPagination()}
        </div>
        {currentSlide === studyWords.length - 1 && (
          <div className="text-center mt-4">
            <Button
              size="3"
              color="blue"
              className="px-5"
              onClick={handleSubmission}
            >
              제출하기
            </Button>
          </div>
        )}
      </Box>
    </Container>
  );
};

export default StudyStart;

import React, { useState, useEffect, useRef } from "react";
import UseFetchToken from "../hooks/UseFetchToken";
import { toast, ToastContainer } from "react-toastify";
import { StudyClassification, StudyType } from "../constants/Constants";
import {
  Box,
  Button,
  Spinner,
  Text,
  Radio,
  Checkbox,
  Flex,
} from "@radix-ui/themes";
import { MagnifyingGlassIcon, Cross1Icon } from "@radix-ui/react-icons";

const StudyAssign = () => {
  const StudyTarget = {
    ENGLISH: { name: "ENGLISH", value: "영어 단어 맞추기" },
    KOREAN: { name: "KOREAN", value: "한글 뜻 맞추기" },
    values() {
      return Object.values(this).filter((v) => typeof v === "object");
    },
  };

  // 학생 검색 관련 상태와 메서드
  const [privateOnly, setPrivateOnly] = useState(true);
  const [studentSearchKeyword, setStudentSearchKeyword] = useState("");
  const [studentSearchType, setStudentSearchType] = useState("name");
  const [studentSchoolGrade, setStudentSchoolGrade] = useState(null);
  const [studentResults, setStudentResults] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isStudentSearching, setIsStudentSearching] = useState(false);
  const studentSearchTimeoutRef = useRef(null);
  const studentDropdownRef = useRef(null);
  const [isStudentDropdownVisible, setIsStudentDropdownVisible] =
    useState(false);

  // 교재 검색 관련 상태와 메서드
  const [bookSearchKeyword, setBookSearchKeyword] = useState("");
  const [bookSchoolGrade, setBookSchoolGrade] = useState(null);
  const [bookResults, setBookResults] = useState([]);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [isBookSearching, setIsBookSearching] = useState(false);
  const bookSearchTimeoutRef = useRef(null);
  const bookDropdownRef = useRef(null);
  const [isBookDropdownVisible, setIsBookDropdownVisible] = useState(false);

  // 학습 설정 관련 상태
  const [studyType, setStudyType] = useState(StudyType.TRACING.name);
  const [studyClassification, setStudyClassification] = useState(
    StudyClassification.PRACTICE.name
  );
  const [studyTarget, setStudyTarget] = useState(StudyTarget.KOREAN.name);
  const [ignoreCase, setIgnoreCase] = useState(true);
  const [numberOfWords, setNumberOfWords] = useState(20);
  const [shuffleEach, setShuffleEach] = useState(true);

  const grades = [
    { label: "학년 선택", value: null },
    { label: "초등 1학년", value: 1 },
    { label: "초등 2학년", value: 2 },
    { label: "초등 3학년", value: 3 },
    { label: "초등 4학년", value: 4 },
    { label: "초등 5학년", value: 5 },
    { label: "초등 6학년", value: 6 },
    { label: "중등 1학년", value: 7 },
    { label: "중등 2학년", value: 8 },
    { label: "중등 3학년", value: 9 },
    { label: "고등 1학년", value: 10 },
    { label: "고등 2학년", value: 11 },
    { label: "고등 3학년", value: 12 },
  ];

  // 학생 검색 메서드
  const searchStudents = async (keyword) => {
    const size = 100;
    const params = new URLSearchParams({
      page: 1,
      size,
    });

    if (studentSchoolGrade !== null && !isNaN(studentSchoolGrade)) {
      params.append("schoolGrade", studentSchoolGrade);
    }

    if (keyword) {
      params.append(studentSearchType, keyword);
    }

    const data = await UseFetchToken(`/api/students?${params.toString()}`, {
      method: "GET",
    });

    if (data) {
      setStudentResults(data.students || []);
      setIsStudentDropdownVisible(true);
      if (data.pageInfo.total > data.students.length) {
        toast.info(`최대 ${size}건만 조회됩니다.`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      setStudentResults([]);
    }
    setIsStudentSearching(false);
  };

  const handleDelayedStudentSearch = (keyword) => {
    if (studentSearchTimeoutRef.current) {
      clearTimeout(studentSearchTimeoutRef.current);
    }

    setIsStudentSearching(true);
    setStudentResults([]);

    studentSearchTimeoutRef.current = setTimeout(() => {
      searchStudents(keyword);
    }, 1000);
  };

  // 학년 또는 검색 키워드가 변경될 때 검색 수행
  useEffect(() => {
    if (studentSearchKeyword.trim().length > 0 || studentSchoolGrade !== null) {
      handleDelayedStudentSearch(studentSearchKeyword);
    } else {
      setIsStudentSearching(false);
    }
  }, [studentSearchKeyword, studentSchoolGrade, privateOnly]);

  const handleStudyTypeChange = (type) => {
    setStudyType(type);
    if (type === StudyType.TRACING.name) {
      // setStudyClassification(StudyClassification.PRACTICE.name);
    }
  };

  const handleStudentSelect = (student) => {
    if (!selectedStudents.includes(student)) {
      setSelectedStudents([...selectedStudents, student]);
    }
  };

  const handleStudentRemove = (studentId) => {
    setSelectedStudents(
      selectedStudents.filter((student) => student.id !== studentId)
    );
  };

  // 교재 검색 메서드
  const searchBooks = async (keyword) => {
    const size = 100;
    const params = new URLSearchParams({
      page: 1,
      size,
      privateOnly,
    });

    if (bookSchoolGrade !== null && !isNaN(bookSchoolGrade)) {
      params.append("schoolGrade", bookSchoolGrade);
    }

    if (keyword) {
      params.append("keyword", keyword);
    }

    const data = await UseFetchToken(`/api/books?${params.toString()}`, {
      method: "GET",
    });

    if (data) {
      setBookResults(data.books || []);
      setIsBookDropdownVisible(true);
      if (data.pageInfo.total > data.books.length) {
        toast.info(`최대 ${size}건만 조회됩니다.`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      setBookResults([]);
    }
    setIsBookSearching(false);
  };

  const handleDelayedBookSearch = (keyword) => {
    if (bookSearchTimeoutRef.current) {
      clearTimeout(bookSearchTimeoutRef.current);
    }

    setIsBookSearching(true);
    setBookResults([]);

    bookSearchTimeoutRef.current = setTimeout(() => {
      searchBooks(keyword);
    }, 1000);
  };

  // 학년 또는 검색 키워드가 변경될 때 검색 수행
  useEffect(() => {
    if (bookSearchKeyword.trim().length > 0 || bookSchoolGrade !== null) {
      handleDelayedBookSearch(bookSearchKeyword);
    } else {
      setIsBookSearching(false);
    }
  }, [bookSearchKeyword, bookSchoolGrade, privateOnly]);

  const handleBookSelect = (book) => {
    if (!selectedBooks.includes(book)) {
      setSelectedBooks([...selectedBooks, book]);
    }
  };

  const handleBookRemove = (bookId) => {
    setSelectedBooks(selectedBooks.filter((book) => book.id !== bookId));
  };

  const handleAssignHomework = async () => {
    if (selectedStudents.length === 0 || selectedBooks.length === 0) {
      toast.error("학생과 교재를 모두 선택해주세요.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const homeworkData = {
      bookIds: selectedBooks.map((book) => book.id),
      ignoreCase: ignoreCase,
      numberOfWords: numberOfWords,
      type: studyType,
      classification: studyClassification,
      target: studyTarget,
      studentIds: selectedStudents.map((student) => student.id),
      shuffleEach: shuffleEach,
    };

    const data = await UseFetchToken("/api/studies/assign", {
      method: "POST",
      body: JSON.stringify(homeworkData),
    });

    if (data) {
      toast.success("숙제가 등록되었습니다!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      // Resetting the state variables
      setTimeout(() => {
        setSelectedStudents([]);
        setSelectedBooks([]);
        setStudentSearchKeyword("");
        setStudentSchoolGrade(null);
        setBookSearchKeyword("");
        setBookSchoolGrade(null);
        setStudyType(StudyType.TRACING.name);
        setStudyClassification(StudyClassification.PRACTICE.name);
        setStudyTarget(StudyTarget.KOREAN.name);
        setIgnoreCase(true);
        setNumberOfWords(20);
        setShuffleEach(true);

        // scroll to top
        window.scroll({ top: 0, behavior: "smooth" });
      }, 1000);
    }
  };

  // 목록 바깥 클릭 시 목록 닫기 기능
  const handleClickOutside = (event) => {
    if (
      studentDropdownRef.current &&
      !studentDropdownRef.current.contains(event.target)
    ) {
      setIsStudentDropdownVisible(false);
    }
    if (
      bookDropdownRef.current &&
      !bookDropdownRef.current.contains(event.target)
    ) {
      setIsBookDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box maxWidth="1245px" mx="auto" mb="9">
      <ToastContainer />
      <h1 className="headline-m font-bold mt-10 mb-16">숙제 등록</h1>

      {/* 학생 검색 영역 */}
      <div className="bg-neutral-5 p-5 rounded-lg space-y-4">
        <h2 className="headline-s font-bold">학생 검색</h2>

        <div className="relative">
          <div className="flex gap-4">
            <select
              className="p-2 border rounded"
              value={studentSchoolGrade || ""}
              onChange={(e) =>
                setStudentSchoolGrade(
                  e.target.value ? Number(e.target.value) : null
                )
              }
              onClick={(e) => {
                setStudentSchoolGrade(
                  e.target.value ? Number(e.target.value) : null
                );
                setIsStudentDropdownVisible(true);
              }}
            >
              {grades.map((grade) => (
                <option key={grade.value} value={grade.value}>
                  {grade.label}
                </option>
              ))}
            </select>
            <div className="flex w-160 items-center divide-x bg-white border rounded">
              <select
                className="w-1/4 p-2"
                value={studentSearchType}
                onChange={(e) => setStudentSearchType(e.target.value)}
              >
                <option value="name">학생이름</option>
                <option value="schoolName">학교이름</option>
                <option value="attendanceId">출석아이디</option>
              </select>
              <div className="flex gap-1 items-center px-2 flex-1">
                <MagnifyingGlassIcon color="#3AACF5" />
                <input
                  type="text"
                  className="flex-1"
                  placeholder="학생 검색어를 입력하세요"
                  value={studentSearchKeyword}
                  onChange={(e) => {
                    setStudentSearchKeyword(e.target.value);
                    setIsStudentDropdownVisible(true);
                  }}
                  onClick={() => setIsStudentDropdownVisible(true)}
                />
              </div>
            </div>
          </div>
          {isStudentSearching && (
            <div className="w-full flex justify-center items-center mt-2">
              <Spinner />
            </div>
          )}
          {!isStudentSearching && isStudentDropdownVisible && (
            <ul
              className="absolute left-0 top-10 z-10 w-full max-h-40 p-2 overflow-y-auto bg-white border border-gray-300 rounded shadow-lg"
              ref={studentDropdownRef}
            >
              {studentResults.length > 0 ? (
                studentResults.map((student) => (
                  <li
                    key={student.id}
                    className="p-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleStudentSelect(student)}
                  >
                    {student.name} ({student.schoolName})
                  </li>
                ))
              ) : (
                <li className="p-2 text-center text-gray-500">
                  {studentSearchKeyword.trim().length === 0 &&
                  studentSchoolGrade === null
                    ? "학생 검색어를 입력하세요."
                    : "검색 결과가 없습니다."}
                </li>
              )}
            </ul>
          )}
        </div>
        {selectedStudents.length > 0 && (
          <div className="mt-4">
            <h3 className="font-semibold">선택된 학생:</h3>
            <ul className="space-y-1">
              {selectedStudents.map((student) => (
                <li key={student.id} className="flex items-center">
                  <span className="mr-2">
                    {student.name} ({student.schoolName})
                  </span>
                  <button
                    className="p-1 text-white bg-warn rounded"
                    onClick={() => handleStudentRemove(student.id)}
                  >
                    <Cross1Icon />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* 교재 검색 영역 */}
      <div className="bg-neutral-5 p-5 rounded-lg space-y-4 mt-4">
        <h2 className="headline-s font-bold">교재 검색</h2>
        <div className="relative">
          <div className="flex items-center">
            <div className="flex w-160 items-center divide-x bg-white border rounded">
              <select
                className="w-1/4 p-2"
                value={bookSchoolGrade || ""}
                onChange={(e) =>
                  setBookSchoolGrade(
                    e.target.value ? Number(e.target.value) : null
                  )
                }
                onClick={(e) => {
                  setBookSchoolGrade(
                    e.target.value ? Number(e.target.value) : null
                  );
                  setIsBookDropdownVisible(true);
                }}
              >
                {grades.map((grade) => (
                  <option key={grade.value} value={grade.value}>
                    {grade.label}
                  </option>
                ))}
              </select>
              <div className="flex gap-1 items-center px-2 flex-1">
                <MagnifyingGlassIcon color="#3AACF5" />
                <input
                  type="text"
                  className="flex-1"
                  placeholder="교재 검색어를 입력하세요"
                  value={bookSearchKeyword}
                  onChange={(e) => {
                    setBookSearchKeyword(e.target.value);
                    setIsBookDropdownVisible(true);
                  }}
                  onClick={() => setIsBookDropdownVisible(true)}
                />
              </div>
            </div>

            <Text as="label" size="2" className="ml-3">
              <Flex as="span" gap="2">
                <Checkbox
                  size="2"
                  color="blue"
                  checked={privateOnly}
                  onCheckedChange={(checked) => setPrivateOnly(checked)}
                />
                우리 학원 교재만 검색
              </Flex>
            </Text>
          </div>

          {isBookSearching && (
            <div className="w-full flex justify-center items-center mt-2">
              <Spinner />
            </div>
          )}
          {!isBookSearching && isBookDropdownVisible && (
            <ul
              className="absolute left-0 top-10 z-10 w-full max-h-40 p-2 overflow-y-auto bg-white border border-gray-300 rounded shadow-lg"
              ref={bookDropdownRef}
            >
              {bookResults.length > 0 ? (
                bookResults.map((book) => (
                  <li
                    key={book.id}
                    className="p-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleBookSelect(book)}
                  >
                    {book.publisher ? book.publisher + " - " : ""}
                    {book.name}
                    {book.chapter ? " " + book.chapter : ""}
                    {book.subject ? " " + book.subject : ""}
                  </li>
                ))
              ) : (
                <li className="p-2 text-center text-gray-500">
                  {bookSearchKeyword.trim().length === 0 &&
                  bookSchoolGrade === null
                    ? "교재 검색어를 입력하세요."
                    : "검색 결과가 없습니다."}
                </li>
              )}
            </ul>
          )}
        </div>

        {selectedBooks.length > 0 && (
          <div className="mt-4">
            <h3 className="font-semibold">선택된 교재:</h3>
            <ul className="space-y-1">
              {selectedBooks.map((book) => (
                <li key={book.id} className="flex items-center">
                  <span className="mr-2">
                    {book.publisher ? book.publisher + " - " : ""}
                    {book.name}
                    {book.chapter ? " " + book.chapter : ""}
                    {book.subject ? " " + book.subject : ""}
                  </span>
                  <button
                    className="p-1 text-white bg-red-500 rounded"
                    onClick={() => handleBookRemove(book.id)}
                  >
                    X
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* 학습 설정 영역 */}
      <div className="space-y-4 border rounded-lg p-4 mt-4">
        <h2 className="text-xl font-semibold">학습 설정</h2>
        <div className="flex items-center gap-10">
          <p className="font-medium">학습 유형</p>
          <div className="flex space-x-4">
            {StudyType.values().map((type) => (
              <Text as="label" size="2" key={type.name}>
                <Radio
                  size="1"
                  name="studyType"
                  color="blue"
                  value={type.name}
                  checked={studyType === type.name}
                  onChange={() => handleStudyTypeChange(type.name)}
                />
                <span className="ml-2">{type.value}</span>
              </Text>
            ))}
          </div>
        </div>

        <div className="flex items-center gap-10">
          <p className="font-medium">학습 분류</p>
          <div className="flex space-x-4">
            {StudyClassification.values().map((classification) => (
              <Text as="label" size="2" key={classification.name}>
                <Radio
                  size="1"
                  name="studyClassification"
                  color="blue"
                  value={classification.name}
                  checked={studyClassification === classification.name}
                  onChange={() => setStudyClassification(classification.name)}
                />
                <span className="ml-2">{classification.value}</span>
              </Text>
            ))}
          </div>
        </div>

        <div className="flex items-center gap-10">
          <p className="font-medium">학습 대상</p>
          <div className="flex space-x-4">
            {StudyTarget.values().map((target) => (
              <Text as="label" size="2" key={target.name}>
                <Radio
                  size="1"
                  name="studyTarget"
                  color="blue"
                  value={target.name}
                  checked={studyTarget === target.name}
                  onChange={() => setStudyTarget(target.name)}
                />
                <span className="ml-2">{target.value}</span>
              </Text>
            ))}
          </div>
        </div>

        {/* 기타 설정 */}
        <div>
          <label className="block mb-1 font-medium">단어 수 선택</label>
          <select
            className="w-96 p-2 border rounded"
            value={numberOfWords}
            onChange={(e) => setNumberOfWords(Number(e.target.value))}
          >
            {[20, 30, 40, 50, 60, 70, 80, 90, 100].map((count) => (
              <option key={count} value={count}>
                {count}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center gap-10">
          <Text as="label" size="2">
            <Flex as="span" gap="2">
              <Checkbox
                size="2"
                color="blue"
                checked={ignoreCase}
                onCheckedChange={(checked) => setIgnoreCase(checked)}
              />
              대소문자 무시
            </Flex>
          </Text>

          <Text as="label" size="2" className="ml-3">
            <Flex as="span" gap="2">
              <Checkbox
                size="2"
                color="blue"
                checked={shuffleEach}
                onCheckedChange={(checked) => setShuffleEach(checked)}
              />
              학생마다 다른 순서로 제출
            </Flex>
          </Text>
        </div>
      </div>

      <Button
        color="blue"
        size="3"
        className="w-full mt-4 text-white rounded hover:bg-secondary transition-colors"
        onClick={handleAssignHomework}
      >
        숙제 등록
      </Button>
    </Box>
  );
};

export default StudyAssign;

import React, { useState, useEffect } from "react";

import { Container, Button, Select, Tooltip, Table } from "@radix-ui/themes";
import {
  MagnifyingGlassIcon,
  QuestionMarkIcon,
  ChevronLeftIcon,
  DoubleArrowLeftIcon,
  ChevronRightIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";

const Academies = () => {
  const [academies, setAcademies] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useState({
    page: 1,
    size: 10,
    academyName: "",
    academyAddress: "",
  });
  const [inputParams, setInputParams] = useState({
    academyName: "",
    academyAddress: "",
  });
  const [total, setTotal] = useState(null);
  const [searchType, setSearchType] = useState("academyName"); // 검색 타입 (이름 또는 주소)

  const fetchAcademies = async (params) => {
    setLoading(true);
    setError(null);

    const queryParams = {
      page: params.page,
      size: params.size,
      ...(params.academyName && { academyName: params.academyName }),
      ...(params.academyAddress && { academyAddress: params.academyAddress }),
    };

    const queryString = new URLSearchParams(queryParams).toString();
    const url = `${process.env.REACT_APP_API_HOST}/api/academies?${queryString}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const result = await response.json();
        setAcademies(result.data.academies);
        setPageInfo(result.data.pageInfo);
        setTotal(result.data.total);
      } else {
        setError("학원 목록을 불러오는 데 실패했습니다.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("학원 목록을 불러오는 중 오류가 발생했습니다.");
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchAcademies(searchParams);
  }, [searchParams]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen text-red-500">
        {error}
      </div>
    );
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleSearchTypeChange = (newSearchType) => {
    setSearchType(newSearchType);

    // 선택한 검색 타입에 따라 다른 입력 필드의 값을 null로 설정
    if (newSearchType === "academyName") {
      setInputParams((prevParams) => ({
        ...prevParams,
        academyName: "",
        academyAddress: null,
      }));
    } else if (newSearchType === "academyAddress") {
      setInputParams((prevParams) => ({
        ...prevParams,
        academyName: null,
        academyAddress: "",
      }));
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchParams((prevParams) => ({
      ...prevParams,
      academyName: inputParams.academyName,
      academyAddress: inputParams.academyAddress,
      page: 1, // 검색 조건 변경 시 첫 페이지로 이동
    }));
  };

  const handlePageChange = (newPage) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      page: newPage,
    }));
  };

  const handlePageSetChange = (direction) => {
    const startPage = Math.floor((pageInfo.page - 1) / 10) * 10 + 1;
    const newPage =
      direction === "first"
        ? 1
        : direction === "last"
        ? pageInfo.lastPage
        : direction === "prev"
        ? Math.max(1, startPage - 10)
        : Math.min(pageInfo.lastPage, startPage + 10);

    setSearchParams((prevParams) => ({
      ...prevParams,
      page: newPage,
    }));
  };

  const renderPagination = () => {
    const startPage = Math.floor((pageInfo.page - 1) / 10) * 10 + 1;
    const endPage = Math.min(startPage + 9, pageInfo.lastPage);
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    const isFirstSet = pageInfo.page <= 10;
    const isLastSet = endPage === pageInfo.lastPage;

    return (
      <div className="mt-4 flex justify-center space-x-2">
        <button
          onClick={() => handlePageSetChange("first")}
          disabled={isFirstSet}
          className={`px-2 py-1 bg-white text-neutral-50 rounded ${
            isFirstSet && "opacity-50 cursor-not-allowed"
          }`}
        >
          <DoubleArrowLeftIcon width="18" height="18" />
        </button>
        <button
          onClick={() => handlePageSetChange("prev")}
          disabled={isFirstSet}
          className={`px-5 py-3 bg-white text-neutral-50 rounded ${
            isFirstSet && "opacity-50 cursor-not-allowed"
          }`}
        >
          <ChevronLeftIcon width="18" height="18" />
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={`px-5 py-3 ${
              number === pageInfo.page
                ? "bg-neutral-5 text-neutral-50 rounded-lg"
                : "bg-white text-neutral-50 rounded-lg"
            } rounded`}
          >
            {number}
          </button>
        ))}
        <button
          onClick={() => handlePageSetChange("next")}
          disabled={isLastSet}
          className={`px-2 py-1 bg-white text-neutral-50 rounded ${
            isLastSet && "opacity-50 cursor-not-allowed"
          }`}
        >
          <ChevronRightIcon width="18" height="18" />
        </button>
        <button
          onClick={() => handlePageSetChange("last")}
          disabled={isLastSet}
          className={`px-2 py-1 bg-white text-neutral-50 rounded ${
            isLastSet && "opacity-50 cursor-not-allowed"
          }`}
        >
          <DoubleArrowRightIcon width="18" height="18" />
        </button>
      </div>
    );
  };

  return (
    <Container size="4" p="5">
      <div className="flex justify-between items-center">
        <h2 className="headline-l font-bold mt-3 mb-10">참여학원 목록</h2>
        <form onSubmit={handleSearchSubmit} className="mb-4 space-y-4">
          <div className="flex justify-end items-center space-x-1">
            <div className="flex items-center space-x-2 border rounded-lg py-2 px-3">
              <Select.Root
                value={searchType}
                onValueChange={handleSearchTypeChange}
              >
                <Select.Trigger
                  variant="ghost"
                  className="text-black"
                ></Select.Trigger>
                <Select.Content>
                  <Select.Item value="academyName">이름으로 검색</Select.Item>
                  <Select.Item value="academyAddress">주소로 검색</Select.Item>
                </Select.Content>
              </Select.Root>

              <div className="flex items-center gap-1 border-l pl-2">
                <MagnifyingGlassIcon color="#3AACF5" width="18" height="18" />
                <input
                  type="text"
                  name={searchType}
                  value={inputParams[searchType] || ""} // 값이 null일 경우 빈 문자열 처리
                  onChange={handleInputChange}
                  placeholder={
                    searchType === "academyName"
                      ? "학원 이름을 검색해 주세요."
                      : "학원 주소를 검색해 주세요."
                  }
                  className="w-128 px-1"
                />
              </div>
            </div>
            <Button type="submit" color="blue" size="3">
              검색
            </Button>
          </div>
        </form>
      </div>

      {total && (
        <div className="flex gap-2 items-center mb-4 text-left">
          <p className="text-neutral-50">
            오늘까지 {total}개의 학원이 함께 하고 있습니다
          </p>
          <Tooltip content="비공개 학원을 포함한 수입니다.">
            <div className=" bg-neutral-70 rounded-full text-white p-0.5">
              <QuestionMarkIcon width="11" height="11" />
            </div>
          </Tooltip>
        </div>
      )}
      <div className="max-w-full mx-auto">
        <Table.Root variant="ghost" className="mb-7">
          <Table.Header>
            <Table.Row className="bg-neutral-5 text-neutral-70">
              <Table.ColumnHeaderCell py="4" className="shadow-none">
                학원 이름
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell py="4" className="shadow-none">
                주소
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell py="4" className="shadow-none">
                전화번호
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell py="4" className="shadow-none">
                이메일
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell py="4" className="shadow-none">
                가입일
              </Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {academies.map((academy) => {
              return (
                <Table.Row key={academy.id} className="hover:bg-sky-50">
                  <Table.RowHeaderCell className="shadow-none">
                    {academy.name}
                  </Table.RowHeaderCell>
                  <Table.Cell py="4" className="shadow-none">
                    {(() => {
                      const { street, addressDetail, postalCode } = academy;
                      let address = street;
                      if (addressDetail.trim()) {
                        address += `, ${addressDetail}`;
                      }
                      if (postalCode) {
                        address += `(${postalCode})`;
                      }
                      if (!address) {
                        address = "주소 없음";
                      }
                      return address;
                    })()}
                  </Table.Cell>
                  <Table.Cell py="4" className="shadow-none">
                    {academy.phone}
                  </Table.Cell>
                  <Table.Cell py="4" className="shadow-none">
                    {academy.email}
                  </Table.Cell>
                  <Table.Cell py="4" className="shadow-none">
                    {(() => {
                      const date = new Date(academy.createdDateTime);
                      const year = date.getFullYear();
                      const month = ("0" + (date.getMonth() + 1)).slice(-2);
                      const day = ("0" + date.getDate()).slice(-2);
                      return `${year}년 ${month}월 ${day}일`;
                    })()}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Root>
        {renderPagination()}
      </div>
    </Container>
  );
};

export default Academies;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { StudyClassification, StudyType } from "../constants/Constants";
import UseFetchToken from "../hooks/UseFetchToken";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  Checkbox,
  Radio,
} from "@radix-ui/themes";
import { MagnifyingGlassIcon, Cross1Icon } from "@radix-ui/react-icons";

const StudyNew = () => {
  const StudyTarget = {
    ENGLISH: { name: "ENGLISH", value: "영어 단어 맞추기" },
    KOREAN: { name: "KOREAN", value: "한글 뜻 맞추기" },
    values() {
      return Object.values(this).filter((v) => typeof v === "object");
    },
  };

  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [privateOnly, setPrivateOnly] = useState(true);
  const [bookResults, setBookResults] = useState([]);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [studyType, setStudyType] = useState(StudyType.TRACING.name);
  const [studyClassification, setStudyClassification] = useState(
    StudyClassification.PRACTICE.name
  );
  const [studyTarget, setStudyTarget] = useState(StudyTarget.KOREAN.name);
  const [ignoreCase, setIgnoreCase] = useState(true);
  const [numberOfWords, setNumberOfWords] = useState(50);
  const [schoolGrade, setSchoolGrade] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const searchTimeoutRef = useRef(null);
  const searchKeywordRef = useRef(searchKeyword);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const dropdownRef = useRef(null);

  const grades = [
    { label: "학년 선택", value: null },
    { label: "초등 1학년", value: 1 },
    { label: "초등 2학년", value: 2 },
    { label: "초등 3학년", value: 3 },
    { label: "초등 4학년", value: 4 },
    { label: "초등 5학년", value: 5 },
    { label: "초등 6학년", value: 6 },
    { label: "중등 1학년", value: 7 },
    { label: "중등 2학년", value: 8 },
    { label: "중등 3학년", value: 9 },
    { label: "고등 1학년", value: 10 },
    { label: "고등 2학년", value: 11 },
    { label: "고등 3학년", value: 12 },
  ];

  const searchBooks = async (keyword) => {
    const size = 100;
    const params = new URLSearchParams({
      page: 1,
      size: size,
      keyword: keyword,
      privateOnly: privateOnly,
    });

    if (schoolGrade !== null && !isNaN(schoolGrade)) {
      params.append("schoolGrade", schoolGrade);
    }

    const data = await UseFetchToken(`/api/books?${params.toString()}`, {
      method: "GET",
    });
    if (data) {
      setBookResults(data.books || []);
      setIsDropdownVisible(true);
      if (data.pageInfo.total > data.books.length) {
        toast.info(`최대 ${size}건만 조회됩니다.`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    setIsSearching(false);
  };

  const handleStudyTypeChange = (type) => {
    setStudyType(type);
    if (type === StudyType.TRACING.name) {
      // setStudyClassification(StudyClassification.PRACTICE.name);
    }
  };

  const handleDelayedSearch = (keyword) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    setIsSearching(true);
    setBookResults([]);

    searchTimeoutRef.current = setTimeout(() => {
      searchBooks(keyword);
    }, 1000);
  };

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
      return;
    }

    searchKeywordRef.current = searchKeyword;
    handleDelayedSearch(searchKeywordRef.current);

    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, [searchKeyword, schoolGrade, privateOnly]);

  const renderSchoolGradeOptions = () => {
    return grades.map((grade) => (
      <option key={grade.value} value={grade.value}>
        {grade.label}
      </option>
    ));
  };

  const handleBookSelect = (book) => {
    if (!selectedBooks.includes(book)) {
      setSelectedBooks([...selectedBooks, book]);
    }
  };

  const handleBookRemove = (bookId) => {
    setSelectedBooks(selectedBooks.filter((book) => book.id !== bookId));
  };

  const handleStudyStart = async () => {
    if (selectedBooks.length === 0) {
      toast.error("교재를 선택해주세요.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const studyData = {
      bookIds: selectedBooks.map((book) => book.id),
      type: studyType,
      classification: studyClassification,
      target: studyTarget,
      ignoreCase: ignoreCase,
      numberOfWords, // Send selected number of words
    };

    const data = await UseFetchToken("/api/studies", {
      method: "POST",
      body: JSON.stringify(studyData),
    });

    if (data && data.studyId) {
      toast.success("학습을 시작합니다!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        navigate(`/studies/${data.studyId}/start`);
      }, 1000);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Container className="h-fill min-h-fit flex justify-center items-center study-container p-5">
      <Box
        maxWidth="1245px"
        mx="auto"
        className="flex flex-col min-h-[68vh] bg-white rounded-xl p-5"
      >
        <ToastContainer />
        <h1 className="headline-m font-bold mb-5 pb-5 border-b">
          📕새 학습 시작
        </h1>

        <Flex direction="column" justify="between" className="h-full flex-1">
          <div className="space-y-4 border p-5 rounded-lg">
            <div className="flex items-center">
              <div className="w-full">
                <label className="block mb-1 font-bold text-xl">
                  교재 검색
                </label>
                <div className="flex flex-wrap gap-2 items-center">
                  <div className="flex max-md:flex-col w-160 md:items-center md:divide-x max-md:divide-y border rounded">
                    <select
                      className="p-2"
                      value={schoolGrade || ""}
                      onChange={(e) =>
                        setSchoolGrade(
                          e.target.value ? Number(e.target.value) : null
                        )
                      }
                      onClick={(e) => {
                        setSchoolGrade(
                          e.target.value ? Number(e.target.value) : null
                        );
                        setIsDropdownVisible(true);
                      }}
                    >
                      {renderSchoolGradeOptions()}
                    </select>
                    <div className="flex gap-1 items-center p-2 flex-1">
                      <MagnifyingGlassIcon color="#3AACF5" />
                      <input
                        type="text"
                        className="flex-1"
                        placeholder="찾으시는 교재를 입력해주세요."
                        value={searchKeyword}
                        onChange={(e) => {
                          setSearchKeyword(e.target.value);
                          setIsDropdownVisible(true);
                        }}
                        onClick={() => setIsDropdownVisible(true)}
                      />
                    </div>
                  </div>

                  <Text as="label" size="2" className="ml-2">
                    <Flex as="span" gap="2">
                      <Checkbox
                        size="2"
                        color="blue"
                        checked={privateOnly}
                        onChange={(e) => setPrivateOnly(e.target.checked)}
                      />
                      우리 학원 교재만 검색
                    </Flex>
                  </Text>
                </div>

                {isSearching && <Spinner className="mt-1" />}
                {isDropdownVisible && (
                  <div className="relative" ref={dropdownRef}>
                    {bookResults.length > 0 ? (
                      <ul className="absolute z-10 w-full max-h-40 p-2 overflow-y-auto bg-white border border-gray-300 rounded shadow-lg">
                        {bookResults.map((book) => (
                          <li
                            key={book.id}
                            className="p-2 cursor-pointer hover:bg-gray-200"
                            onClick={() => handleBookSelect(book)}
                          >
                            {book.publisher ? book.publisher + " - " : ""}
                            {book.name}
                            {book.chapter ? " " + book.chapter : ""}
                            {book.subject ? " " + book.subject : ""}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      !isSearching && (
                        <div className="absolute z-10 w-full p-2 bg-white border border-gray-300 rounded shadow-lg">
                          {searchKeyword.trim().length === 0 ? (
                            <p className="text-center text-gray-500">
                              찾으시는 교재를 입력하세요.
                            </p>
                          ) : (
                            <p className="text-center text-gray-500">
                              검색 결과가 없습니다.
                            </p>
                          )}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>

            {selectedBooks.length > 0 && (
              <div>
                <p className="font-semibold">선택된 교재</p>
                <ul className="space-y-1">
                  {selectedBooks.map((book) => (
                    <li key={book.id} className="flex items-center">
                      <span className="mr-2">
                        {book.publisher ? book.publisher + " - " : ""}
                        {book.name}
                        {book.chapter ? " " + book.chapter : ""}
                        {book.subject ? " " + book.subject : ""}
                      </span>
                      <button
                        className="p-1 text-white bg-red-500 rounded"
                        onClick={() => handleBookRemove(book.id)}
                      >
                        <Cross1Icon />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="space-y-4">
              <div className="flex max-md:flex-col md:items-center gap-x-10">
                <p className="font-medium">학습 유형</p>
                <div className="flex space-x-4">
                  {StudyType.values().map((type) => (
                    <Text as="label" size="2" key={type.name}>
                      <Radio
                        size="1"
                        name="studyType"
                        color="blue"
                        value={type.name}
                        checked={studyType === type.name}
                        onChange={() => handleStudyTypeChange(type.name)}
                      />
                      <span className="ml-2">{type.value}</span>
                    </Text>
                  ))}
                </div>
              </div>

              <div className="flex max-md:flex-col md:items-center gap-x-10">
                <p className="font-medium">학습 분류</p>
                <div className="flex space-x-4">
                  {StudyClassification.values().map((classification) => (
                    <Text as="label" size="2" key={classification.name}>
                      <Radio
                        size="1"
                        name="studyClassification"
                        value={classification.name}
                        color="blue"
                        checked={studyClassification === classification.name}
                        onChange={() =>
                          setStudyClassification(classification.name)
                        }
                      />
                      <span className="ml-2">{classification.value}</span>
                    </Text>
                  ))}
                </div>
              </div>

              <div className="flex max-md:flex-col md:items-center gap-x-10">
                <p className="font-medium">학습 대상</p>
                <div className="flex space-x-4">
                  {StudyTarget.values().map((target) => (
                    <Text as="label" size="2" key={target.name}>
                      <Radio
                        size="1"
                        name="studyTarget"
                        value={target.name}
                        color="blue"
                        checked={studyTarget === target.name}
                        onChange={() => setStudyTarget(target.name)}
                      />
                      <span className="ml-2">{target.value}</span>
                    </Text>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <label className="block mb-1 font-medium">단어 수 선택</label>
              <select
                className="max-md:w-full md:w-80 p-2 border rounded"
                value={numberOfWords}
                onChange={(e) => setNumberOfWords(Number(e.target.value))}
              >
                {[20, 30, 40, 50, 60, 70, 80, 90, 100].map((count) => (
                  <option key={count} value={count}>
                    {count}
                  </option>
                ))}
              </select>
            </div>

            <Text as="label">
              <Flex as="span" align="center" gap="2" className="mt-2">
                <Checkbox
                  size="2"
                  color="blue"
                  checked={ignoreCase}
                  onCheckedChange={(checked) => setIgnoreCase(checked)}
                />
                대소문자 무시
              </Flex>
            </Text>
          </div>

          <Button
            color="blue"
            size="3"
            className="w-full mt-5"
            onClick={handleStudyStart}
          >
            학습 시작
          </Button>
        </Flex>
      </Box>
    </Container>
  );
};

export default StudyNew;
